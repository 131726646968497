<template>
  <v-menu
    v-model="menu"
    width="600"
    offset-x
  >
    <template #activator="{ attrs }">
      <slot
        name="activator"
        :on="menuVOn"
        :attrs="attrs"
        :edit="!!editModelFileNode"
      >
        <v-badge
          :value="!!editModelFileNode"
          bordered
          icon="mdi-pencil"
          color="secondary"
          overlap
        >
          <v-btn
            v-bind="attrs"
            class="bg-ease"
            rounded
            dark
            v-on="menuVOn"
          >
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </v-badge>
      </slot>
    </template>
    <v-list class="py-0">
      <FileSelect
        :value="editModelFileNode"
        :simulation-model-uuid="model"
        filter-parent-maplayer
        @input="onSelectFile"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            text
            large
            block
            :loading="
              editModelFileNode &&
                editModelFileNode.isLoading === true
            "
            v-on="on"
          >
            {{ $t('edit') }}
          </v-btn>
        </template>
      </FileSelect>
      <v-btn
        text
        large
        block
        @click="createNewFile"
        @mouseup="closeMenu"
      >
        {{ $t('new') }}
      </v-btn>
      <FileSelect
        :value="editModelFileNode"
        :permissions="['update']"
        @input="onLoadFile"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            text
            large
            block
            :loading="
              editModelFileNode &&
                editModelFileNode.isLoading === true
            "
            v-on="on"
          >
            {{ $t('load') }}
          </v-btn>
        </template>
      </FileSelect>
    </v-list>
    <InterpolatingModelDialog
      v-if="model === SIM_MODEL.INTERPOLATING"
      v-model="dialog"
    />
    <ModflowModelDialog
      v-if="
        [
          SIM_MODEL.MODFLOW,
          SIM_MODEL.FEMWATER_MESH
        ].includes(model)
      "
      v-model="dialog"
    />
  </v-menu>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect.vue'
import InterpolatingModelDialog from '../Interpolating/InterpolatingModelDialog.vue'
import ModflowModelDialog from '../Modflow/Model/ModflowModelDialog.vue'

import { SIM_MODEL } from '@/models'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SimulationModeMenu',

  components: {
    FileSelect,
    InterpolatingModelDialog,
    ModflowModelDialog
  },

  data: (vm) => ({
    SIM_MODEL,
    menuVOn: { click: vm.toggleMenu },
    menu: false,
    dialog: false
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),
    editModelFileNode: {
      get() {
        return this.$store.state.simulation.editModelFileNode
      },
      set(newVal) {
        this.$store.commit('simulation/setState', { editModelFileNode: newVal })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    targetMapset() {
      return this?.currentLayerNode?.parent
    }
  },

  methods: {
    toggleMenu() {
      if (this.editModelFileNode) {
        this.menu = false
        this.dialog = true

        return
      }

      this.menu = !this.menu
    },
    closeMenu() {
      this.menu = false
    },
    createNewFile() {
      this.dialog = true
      this.closeMenu()
    },
    onSelectFile(fileNode) {
      this.editModelFileNode = fileNode
      this.$store.dispatch('globalFiles/fetchFileContent', {
        project: this.project,
        fileNode
      })
        .then(() => {
          this.dialog = true
          this.closeMenu()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })
        })
    },
    onLoadFile(fileNode) {
      // const layerNode = this.currentLayerNode
      this.editModelFileNode = fileNode
      this.$store.dispatch('globalFiles/fetchFileContent', {
        project: this.project,
        fileNode
      })
        .then(() => {
          return this.$store.dispatch('globalFiles/updateMaplayer', {
            project: this.project,
            fileNode,
            data: {
              maplayer_type: 'SETTINGS_FILE',
              simulation_model_uuid: this.model
            }
          })
        })
        .then(() => {
          this.dialog = true
          this.closeMenu()
        })
        .catch(error => {
          this.editModelFileNode = null
          this.$store.dispatch('snackbar/showError', { content: error })

          return Promise.reject(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
