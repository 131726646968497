<template>
  <!-- retain-focus 不在官方doc, 設為false點地圖時才不會focus trap在v-dialog -->
  <v-dialog
    v-model="dialog"
    :transition="false"
    :retain-focus="false"
    content-class="search-bar-dialog elevation-0"
    width="unset"
    hide-overlay
    persistent
    no-click-animation
    @click:outside="clear"
  >
    <v-toolbar
      v-show="geoJsonLayerNodes.length"
      class="bg-ease elevation-2"
      dense
      :collapse="collapse"
    >
      <v-btn
        icon
        dark
        @click="collapse = !collapse"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-autocomplete
        v-if="!collapse"
        v-model="select"
        class="mx-4"
        :loading="isLoading"
        :items="
          search
            ?searchItems
            :[]
        "
        :search-input.sync="search"
        :filter="filterItems"
        return-object
        flat
        hide-no-data
        hide-details
        :label="$t('page.map.placeholder_search_geojson_keyword')"
        solo
        @update:search-input="onSearchInput"
        @change="selectFeature"
      >
        <template #item="{item, on, attrs}">
          <v-list-item
            v-bind="attrs"
            two-line
            v-on="on"
            @mouseenter="onMousemoveSearchItem(item)"
            @mouseleave="onMouseleaveSearchItem(item)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-row
                  class="pr-1 flex-nowrap"
                  no-gutters
                  align="center"
                >
                  <span class="grey--text">{{ item.text.folderName }}</span>
                  <v-icon
                    color="grey"
                    small
                  >
                    mdi-chevron-right
                  </v-icon>
                  <span class="grey--text">{{ item.text.mapsetName }}</span>
                  <v-icon
                    color="grey"
                    small
                  >
                    mdi-chevron-right
                  </v-icon>
                  <span class="primary--text">{{ item.text.layerName }}</span>
                  <template v-if="item.text.featureOrder || item.text.featureOrder===0">
                    <v-icon
                      color="grey"
                      small
                    >
                      mdi-chevron-right
                    </v-icon>
                    <span class="primary--text">{{ item.text.featureOrder }}</span>
                  </template>
                </v-row>
              </v-list-item-title>
              <v-list-item-subtitle v-if="!!search">
                {...,
                <span>{{ item.value.prevMatch }}</span>
                <span class="red--text text--darken-2">{{ item.value.match }}</span>
                <span>{{ item.value.nextMatch }}</span>
                ,...}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-toolbar>
  </v-dialog>
</template>

<script>
import { LngLatBounds } from 'mapbox-gl'
import { flattenDeep, chunk } from 'lodash'

import { FEATURE_MULTI_LAYER } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SearchBar',

  data: vm => ({
    dialog: true,
    select: null,
    search: null,
    collapse: true,
    isLoading: false,
    retainFocus: false,
    searchItems: [], // performance關係, 不能用computed
    timerSearch: null,
    delaySearchInput: 200,
    timerHover: null,
    delayHover: 400,
    hoveredStateId: null
  }),

  computed: {
    ...mapState({
      layerTree: state => state.map.layerTree
    }),
    ...mapGetters({
      map: 'map/map',
      layerNodes: 'map/layerNodes'
    }),

    geoJsonLayerNodes() {
      return this.layerNodes
        .filter(layerNode => layerNode.source && !layerNode.is3DFile)
    }
  },

  methods: {
    onSearchInput(newSearch) {
      if (newSearch == null) {
        return
      }

      this.isLoading = true
      clearTimeout(this.timerSearch)
      this.timerSearch = setTimeout(async () => {
        this.searchItems = await this.getSearchItems()
          .finally(() => {
            this.isLoading = false
          })
      }, this.delaySearchInput)
    },
    async getSearchItems() {
      if (!Array.isArray(this.layerNodes)) {
        return []
      }

      return this.geoJsonLayerNodes
        .filter(layerNode => layerNode.visibility !== 'none') // 只搜尋沒有hide的
        .reverse() // 上層的排在前面
        .flatMap(layerNode => {
          const mapset = layerNode.parent
          const folder = mapset.parent

          let mapsetName = mapset.name
          if (mapsetName.length > 12) {
            mapsetName = `${mapsetName.slice(0, 6)}...${mapsetName.slice(-6)}`
          }
          let folderName = folder.name
          if (folderName.length > 12) {
            folderName = `${folderName.slice(0, 6)}...${folderName.slice(-6)}`
          }
          let layerName = layerNode.name
          if (layerName.length > 24) {
            layerName = `${layerName.slice(0, 12)}...${layerName.slice(-12)}`
          }

          const features = layerNode.source.geoJsonData.features

          return features.flatMap((feature, iFeature) => {
            // feature boundingBox
            const boundingBox = new LngLatBounds()
            const coords = chunk(
              flattenDeep(feature.geometry.coordinates),
              2
            )
            coords.forEach(coord => {
              try {
                boundingBox.extend(coord)
              } catch (error) {
                console.log(error)
                console.log(feature)
              }
            })

            const searchItem = {
              layerNode,
              feature,
              featureIndex: iFeature,
              featureBoundingBox: boundingBox,
              text: {
                folderName,
                mapsetName,
                layerName
              },
              value: null
            }

            if (!FEATURE_MULTI_LAYER.is(feature)) {
              return {
                ...searchItem,
                value: layerNode.source.searchFeatureProperty(feature.properties, this.search)
              }
            }

            return FEATURE_MULTI_LAYER.getLayerProperties(feature)
              .map(layer => {
                const layerProperties = layer[1]
                let featureOrder = layer[2]
                if (featureOrder.length > 8) {
                  featureOrder = `${featureOrder.slice(0, 4)}...${featureOrder.slice(-4)}`
                }
                return {
                  ...searchItem,
                  text: {
                    ...searchItem.text,
                    featureOrder
                  },
                  value: layerNode.source.searchFeatureProperty(
                    {
                      ...feature.properties,
                      ...layerProperties
                    }, this.search)

                }
              })
          })
        })
    },
    filterItems(item, queryText) {
      if (!queryText || this.isLoading) return false

      return !!item.value
    },
    clear() {
      this.collapse = true
      this.search = null
      this.select = null
    },
    fitFeatureBounds(searchItem) {
      if (!searchItem) {
        return
      }

      const boundingBox = searchItem.featureBoundingBox

      this.layerTree.fitBounds(boundingBox, {
        maxZoom: 18
      })
    },
    onMousemoveSearchItem(searchItem) {
      // set hover state to feature
      try {
        this.hoveredStateId = searchItem.featureIndex
        this.map.setFeatureState(
          {
            source: searchItem.layerNode.source.uuid,
            id: this.hoveredStateId
          },
          { hover: true }
        )
      } catch (error) {}

      // fitBounds after delay
      clearTimeout(this.timerHover)
      this.timerHover = setTimeout(() => {
        this.fitFeatureBounds(searchItem)
      }, this.delayHover)
    },
    onMouseleaveSearchItem(searchItem) {
      clearTimeout(this.timerHover)

      // remove hover state
      if (this.hoveredStateId != null) {
        this.map.setFeatureState(
          {
            source: searchItem.layerNode.source.uuid,
            id: this.hoveredStateId
          },
          { hover: false }
        )
      }
      this.hoveredStateId = null
    },
    selectFeature(searchItem) {
      this.onMouseleaveSearchItem(searchItem)

      this.fitFeatureBounds(searchItem)

      this.clear()
    }
  }

}
</script>

<style lang="scss">
.search-bar-dialog {
  position: absolute;
  top: $map-nav-height;
  right: 280px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) transform, 0.2s cubic-bezier(0.4, 0, 0.2, 1) background-color, 0.2s cubic-bezier(0.4, 0, 0.2, 1) left, 0.2s cubic-bezier(0.4, 0, 0.2, 1) right, 280ms cubic-bezier(0.4, 0, 0.2, 1) box-shadow, 0.25s cubic-bezier(0.4, 0, 0.2, 1) max-width, 0.25s cubic-bezier(0.4, 0, 0.2, 1) width;
}
</style>

<style lang="scss" scoped>
// .search-bar{
//   margin-top: calc(#{$map-nav-height} + 24px);
// }
</style>
