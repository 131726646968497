<template>
  <v-dialog
    v-model="dialog"
    width="60rem"
    hide-overlay
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      >
        <v-btn
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('massbalance_draw_line') }}
        </v-btn>
      </slot>
    </template>

    <v-card
      :elevation="0"
    >
      <v-card-title
        class="
          text-subtitle-1
          bg-ease white--text
        "
      >
        <v-row
          no-gutters
          align="center"
        >
          <v-col class="draggable">
            {{ $t('massbalance_chart') }}
          </v-col>

          <v-col cols="auto">
            <v-btn
              icon
              dark
              x-small
              @click="close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-6">
        <v-row
          no-gutters
          align="center"
        >
          <v-col cols="auto">
            <v-checkbox
              v-model="checkedAll"
              class="mt-0 pt-0"
              dense
              :indeterminate="isIndeterminate"
              :label="$t('select_all')"
              hide-details="auto"
              @change="onSelectAllChange"
            />
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              small
              color="primary"
              @click="redraw"
            >
              {{ $t('redraw') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            v-for="(serie) in series"
            :key="serie"
            cols="2"
          >
            <v-checkbox
              v-model="checkedSeries"
              class="legend-checkbox"
              :value="serie"
              :label="`${serie}`"
              multiple
              dense
              hide-details="auto"
              @change="setSeriesVisible"
            />
          </v-col>
        </v-row>

        <v-row
          class="mt-6"
          no-gutters
          justify="center"
        >
          <highcharts
            ref="chartHead"
            :options="chartHeadOptions"
          />
        </v-row>
        <v-row
          no-gutters
          justify="center"
        >
          <highcharts
            ref="chartFlux"
            :options="chartFluxOptions"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { fixedDigits } from '@/assets/js/utils'

export default {
  name: 'MassbalanceSliceChartDialog',

  components: {
    highcharts: Chart
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    mbSliceData: {
      type: Object,
      required: true
    }
  },

  data: vm => ({
    checkedAll: false,
    show: false,
    checkedSeries: [vm?.mbSliceData?.months?.[0]]
  }),

  computed: {
    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal

        this.$emit('input', newVal)
      }
    },

    series() {
      if (!Array.isArray(this.mbSliceData.months)) {
        return []
      }

      return this.mbSliceData.months
    },
    isIndeterminate() {
      const total = this.series.length
      const checkedCount = this.checkedSeries.length

      return checkedCount > 0 && checkedCount < total
    },
    chartHeadOptions() {
      const innerHead = this.mbSliceData.OUTinner_polyline_h
      const innerDis = this.mbSliceData.inner_dis
      const months = this.mbSliceData.months
      return {
        accessibility: {
          enabled: false
        },
        title: {
          text: '水平衡線圖表-水頭'
        },
        chart: {
          type: 'line',
          width: 730,
          height: 300
        },
        legend: {
          enabled: true
        },
        xAxis: {
          title: {
            text: '距離'
          }
        },
        yAxis: {
          title: {
            text: 'h 水頭'
          }
        },
        // plotOptions: {
        //   series: {
        //     events: {
        //       legendItemClick: this.onLegendItemClick
        //     }
        //   }
        // },
        series: innerHead.map((h, iH) => ({
          name: months[iH],
          visible: iH === 0,
          data: innerDis.map((dis, iDis) => [fixedDigits(dis), fixedDigits(h[iDis])])
        }))
      }
    },
    chartFluxOptions() {
      const innerFlux = this.mbSliceData.OUTinner_polyline_flux
      const innerDis = this.mbSliceData.inner_dis
      const months = this.mbSliceData.months
      return {
        accessibility: {
          enabled: false
        },
        title: {
          text: '水平衡線圖表-水通量'
        },
        chart: {
          type: 'line',
          width: 730,
          height: 300
        },
        legend: {
          enabled: true
        },
        xAxis: {
          title: {
            text: '距離'
          }
        },
        yAxis: {
          title: {
            text: 'Flux 水通量'
          }
        },
        // plotOptions: {
        //   series: {
        //     events: {
        //       legendItemClick: this.onLegendItemClick
        //     }
        //   }
        // },
        series: innerFlux.map((flux, iFlux) => ({
          name: months[iFlux],
          visible: iFlux === 0,
          data: innerDis.map((dis, iDis) => [fixedDigits(dis), fixedDigits(flux[iDis])])
        }))
      }
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    },
    checkedSeries: {
      handler(newVal) {
        this.checkedAll = newVal.length === this.series.length
      },
      immediate: true
    }
  },

  methods: {
    close() {
      this.dialog = false
    },
    onSelectAllChange(selectedAll) {
      this.checkedSeries = selectedAll ? this.series : []

      this.setSeriesVisible()
    },
    setSeriesVisible() {
      const chartHead = this.$refs.chartHead.chart
      const chartFlux = this.$refs.chartFlux.chart

      chartHead.series.forEach(serie => {
        const show = this.checkedSeries.includes(serie.name)

        serie.setVisible(show, false)
      })
      chartFlux.series.forEach(serie => {
        const show = this.checkedSeries.includes(serie.name)

        serie.setVisible(show, false)
      })

      chartHead.redraw()
      chartFlux.redraw()
    },
    onLegendItemClick(e) {
      this.$nextTick(() => {
        const serie = e.target

        if (
          serie.visible &&
          !this.checkedSeries.includes(serie.name)
        ) {
          this.checkedSeries.push(serie.name)
        }

        if (
          !serie.visible &&
          this.checkedSeries.includes(serie.name)
        ) {
          this.checkedSeries = this.checkedSeries.filter(s => s !== serie.name)
        }
      })
    },
    redraw() {
      this.$emit('redraw')
    }
  }
}
</script>

<style lang="scss" scoped>
.legend-checkbox {
  ::v-deep .v-label {
    font-size: 0.9rem;
  }
}
</style>
