<template>
  <!-- retain-focus設為false點地圖時才不會focus trap在v-dialog -->
  <v-dialog
    v-model="dialog"
    :transition="false"
    :retain-focus="false"
    content-class="glbmeshmgr-dialog elevation-0"
    hide-overlay
    persistent
    no-click-animation
  >
    <v-expansion-panels
      v-if="currentLayerNode && currentLayerNode.is3DFile"
      class="glbmeshmgr-expansion-panels"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="primary white--text"
        >
          GLB Meshes
          <template #actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-subheader>
            <p class="mb-0 text-truncate">
              {{ currentLayerNode.name }}
            </p>
          </v-subheader>
          <v-list
            class="mesh-list"
            dense
          >
            <!-- <v-list-item-group color="primary"> -->
            <v-list-item
              v-for="(glbMesh, iGlbMesh) in currentGlbMeshes"
              :key="iGlbMesh"
              :value="glbMesh"
            >
              <v-list-item-action>
                <v-row
                  no-gutters
                  align="center"
                >
                  <!-- <v-col cols="auto">
                    <v-checkbox
                      v-model="checkMeshs"
                      color="primary"
                      multiple
                      dense
                      :value="glbMesh"
                    />
                  </v-col> -->
                  <v-col cols="auto">
                    <v-btn
                      icon
                      small
                      @click.stop="toggleVisible(glbMesh)"
                    >
                      <v-icon small>
                        {{ glbMesh.isEnabled() ?'mdi-eye':'mdi-eye-off' }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      icon
                      small
                      @click.stop="toggleWireframeVisible(glbMesh)"
                    >
                      <v-icon small>
                        mdi-web
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      icon
                      small
                      @click.stop="fitBounds(glbMesh)"
                    >
                      <v-icon small>
                        mdi-crosshairs-gps
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      icon
                      small
                      @click.stop="toggleMeshAxisVisible(glbMesh)"
                    >
                      <v-icon small>
                        {{ currentLayerNode.isMeshAxisEnabled(glbMesh)?'mdi-axis-lock':'mdi-axis-arrow' }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row
                    no-gutters
                    align="center"
                  >
                    <v-col
                      class="text-body-2 d-flex align-center text-truncate"
                      :title="glbMesh.name"
                      style="max-width: 220px;"
                    >
                      <div class="text-truncate">
                        {{ glbMesh.name }}
                      </div>
                    </v-col>
                    <v-col
                      v-if="glbMesh.settings.activeScalarsName"
                      class="ml-2"
                      cols="auto"
                      :title="glbMesh.settings.activeScalarsName"
                      style="width: 35%; max-width: 120px;"
                    >
                      <v-text-field
                        v-if="!canPlot"
                        class="scalars-name-select"
                        :value="glbMesh.settings.activeScalarsName"
                        dense
                        readonly
                        outlined
                        hide-details
                      />
                      <v-select
                        v-else
                        class="scalars-name-select"
                        :value="glbMesh.settings.activeScalarsName"
                        :items="scalarsNames"
                        placeholder="Scalars Name"
                        dense
                        outlined
                        hide-details
                        :readonly="!canPlot"
                        :loading="isPloting"
                        :disabled="isPloting"
                        @input="newVal=>onInputMeshScalarsName(glbMesh, newVal)"
                      />
                    </v-col>
                  </v-row>
                </v-list-item-title>
                <!-- <v-list-item-subititle>
                </v-list-item-subititle> -->
              </v-list-item-content>
            </v-list-item>
            <!-- </v-list-item-group> -->
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-dialog>
</template>

<script>
import { setProperties } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'GlbMeshMgr',

  data: () => ({
    dialog: true
    // checkMeshs: []
  }),

  computed: {
    ...mapState({
      layerTree: state => state.map.layerTree,
      currentLayerNode: state => state.map.currentLayerNode,
      isPloting: state => state.postprocess.plot.isPloting,
      zoom: state => state.map.zoom,
      scale: state => state.map.scale
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      map: 'map/map',
      currentGlbMeshes: 'map/currentGlbMeshes',
      isPostprocessModelByRoute: 'map/isPostprocessModelByRoute'
    }),

    isPostprocessModel() {
      return this.isPostprocessModelByRoute(this.$route)
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    canPlot() {
      return this.isPostprocessModel
    },
    settings() {
      if (this.currentLayerNode.stagingFile) {
        return this.currentLayerNode.stagingFile.settings
      }

      return this.currentLayerNode.settings
    },
    scalarsNames() {
      return this.settings?.plot?.scalarsNames
    },
    hasScalarsNames() {
      return Array.isArray(this.scalarsNames) && this.scalarsNames.length
    },
    legends() {
      const legends = this.settings?.plot?.legends

      if (!Array.isArray(legends)) {
        return []
      }

      return legends
    }
  },

  methods: {
    toggleVisible(glbMesh) {
      this.currentLayerNode.toggleMeshVisible(glbMesh)
    },
    toggleWireframeVisible(glbMesh) {
      this.currentLayerNode.toggleWireframeVisible(glbMesh)
    },
    toggleMeshAxisVisible(glbMesh) {
      const scale = this.scale
      const zoom = this.zoom
      this.currentLayerNode.toggleMeshAxisVisible(glbMesh, {
        zoom,
        scale
      })
    },
    fitBounds(mesh) {
      this.layerTree.fitBounds(this.currentLayerNode.getMapBoundingBoxByMesh(mesh))
    },
    onInputMeshScalarsName(glbMesh, scalarsName) {
      // 假如原本沒stagingFile, 要把原本settings被改的activeScalarsName改回來
      // 不存檔離開時才會復原成原本的
      const oriSettings = this.currentLayerNode.stagingFile ? null : this.currentLayerNode.settings
      const oriScalarsName = glbMesh.settings.activeScalarsName
      const legend = this.legends
        .find(legend => legend.scalarsName === scalarsName)
      if (!legend) {
        return
      }
      setProperties(legend, {
        isPloting: true
      })
      setProperties(glbMesh.settings, {
        activeScalarsName: scalarsName
      })
      this.$store.dispatch('postprocess/plot/start', {
        project: this.project,
        glbLayerNode: this.currentLayerNode,
        plotSettings: legend
      })
        .then(() => {
          // 假如原本沒stagingFile, 要把原本settings被改的activeScalarsName改回來
          // 不存檔離開時才會復原成原本的
          if (oriSettings) {
            const oriMeshSettings = oriSettings.meshes.find(mesh => mesh.name === glbMesh.name)
            if (oriMeshSettings) {
              setProperties(oriMeshSettings, {
                activeScalarsName: oriScalarsName
              })
            }
          }
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
          setProperties(glbMesh.settings, {
            activeScalarsName: oriScalarsName
          })

          throw error
        })
        .finally(() => {
          setProperties(legend, {
            isPloting: false
          })
        })
    }
  }
}
</script>

<style lang="scss">
.glbmeshmgr-dialog.v-dialog {
  position: absolute;
  top: calc(#{$map-nav-height} + 70px);
  right: 0;
  margin: 0;
  width: auto;
  min-width: 200px;
  max-width: 30%;
  max-height: calc(100vh - 226px);
}
</style>

<style lang="scss" scoped>
.glbmeshmgr-expansion-panels {
  .v-expansion-panel-header {
    padding: 8px 0.875rem;
    min-height: 30px;
    font-size: 1rem;
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 8px 0.375rem;
  }
}

.mesh-list {
  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  &.v-list--dense .v-list-item .v-list-item__title,
  &.v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 1rem;
    font-weight: normal;
  }

  .v-list-item {
    padding: 0 0.625rem;
    min-height: 30px;

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__action {
      margin: 0;
    }
  }
}

.v-input.v-select.scalars-name-select,
.v-input.v-text-field.scalars-name-select {
  font-size: 0.875rem;
  border-radius: 0;
  outline-color: #eee !important;

  ::v-deep .v-input__slot {
    height: 30px;
    min-height: 30px;

    fieldset {
      border-color: #eee;
    }

    .v-input__append-inner {
      margin-top: 3px;

      .v-input__icon .v-icon.theme--light {
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }

    .v-select__selection {
      color: $color-primary;
    }
  }
}
</style>
