var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticStyle:{"height":"100%"},style:({
    '--table-wrapper-height': _vm.roleFileNode.update
      ?'calc(100% - 28px)'
      :'100%'
  }),attrs:{"disabled":_vm.isLoading},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"flex-nowrap",staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"overflow-y":"auto","max-height":"100%","flex-basis":"500px"}},[_c('v-data-table',{staticClass:"property-table pl-3 rounded-0",attrs:{"loading":_vm.isLoading,"headers":_vm.tableHeaders,"items":_vm.showProperties,"items-per-page":-1,"item-class":function () { return 'property-tr'; },"dense":"","fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.propertyKey",fn:function(ref){
  var item = ref.item;
return [(item.isFeatureProperties && _vm.roleFileNode.update)?_c('v-input',{attrs:{"value":item.propertyKey,"hide-details":"auto","rules":_vm.rulesPropertyKey}},[_c('FeatureEditer',{attrs:{"disabled":_vm.isLoading,"readonly":!_vm.roleFileNode.update},model:{value:(item.propertyKey),callback:function ($$v) {_vm.$set(item, "propertyKey", $$v)},expression:"item.propertyKey"}})],1):_c('span',[_vm._v(_vm._s(item.propertyKey))])]}},{key:"item.value",fn:function(ref){
  var item = ref.item;
return [(item.computable)?_c('FeatureCalculatorDialog',{attrs:{"geojson":_vm.feature,"paint-type":_vm.paintType},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-btn",attrs:{"rounded":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-calculator ")]),_vm._v(" "+_vm._s(_vm.$t('calculate'))+" ")],1)]}}],null,true)}):_vm._e(),(item.editable)?_c('FeatureEditer',_vm._b({attrs:{"items":(Array.isArray(item.options) && item.options) || [],"type":item.dataType,"disabled":_vm.isLoading,"readonly":!_vm.roleFileNode.update},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},'FeatureEditer',item.attrs || {},false)):_c('span',[_vm._v(_vm._s(item.value))])]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [(item.isFeatureProperties)?_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.removeProperty(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1):_vm._e()]}},{key:"footer",fn:function(){return [(_vm.roleFileNode.update)?_c('v-row',{attrs:{"no-gutters":"","justify":"end","align":"center"}},[_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":_vm.addProperty}},[_vm._v(" "+_vm._s(_vm.$t('add_property'))+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1),(
                [
                  _vm.SIM_MODEL.MODFLOW,
                  _vm.SIM_MODEL.FEMWATER_MESH
                ].includes(_vm.model)
              )?_c('ModflowParametersDialog',{attrs:{"layer-node":_vm.layerNode,"feature-index":_vm.featureIndex,"layer-index":_vm.layerIndex},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"disabled":!!_vm.editSimParamFileNode,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var onTooltip = ref.on;
              var attrsTooltip = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrsTooltip,false),onTooltip),[_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":"","color":"secondary","disabled":!_vm.editSimParamFileNode}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('simulation_parameter_abbreviation'))+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-tune ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.modflow.tip_select_param_file_first')))])])]}}],null,false,214945796)}):_vm._e()],1):_vm._e()]},proxy:true}],null,true)})],1),_c('v-col',{staticClass:"white ml-3",staticStyle:{"overflow-y":"auto","max-height":"100%","flex-basis":"348px"},attrs:{"cols":"5"}},[(_vm.isLoadingDashboards)?_c('v-progress-linear',{attrs:{"absolute":"","color":"primary","indeterminate":""}}):(_vm.hasNoData)?_c('IssueCard',{staticStyle:{"margin-top":"60px"},attrs:{"message":_vm.$t('page.map.no_dashboard'),"not-found":"","img-width":"70%"}}):_vm._l((_vm.dashboards),function(dashboard,iDashboard){return _c('v-hover',{key:(_vm.mapLayerId + "-" + iDashboard),scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card',{staticClass:"dashboard-card mb-7 mx-9",class:{
            'primary--text': !hover,
            primary: hover,
            'mt-7': iDashboard === 0
          },staticStyle:{"cursor":"pointer"},attrs:{"dark":hover,"height":"100px","outlined":"","disabled":!dashboard.panels.length},on:{"click":function($event){return _vm.$emit('click:dashboard', dashboard, _vm.properties, _vm.layerLabel)}}},[_c('v-card-title',[_c('div',{staticClass:"text-truncate",attrs:{"title":dashboard.title},domProps:{"textContent":_vm._s(dashboard.title)}})]),_c('v-card-subtitle',[(dashboard.panels.length)?_c('v-row',{staticClass:"dashboard-description",attrs:{"title":dashboard.description,"no-gutters":""},domProps:{"textContent":_vm._s(dashboard.description)}}):_c('v-row',{attrs:{"lass":"dashboard-description","no-gutters":""}},[_vm._v(" "+_vm._s(_vm.$t('page.map.no_panel'))+" ")])],1)],1)]}}],null,true)})})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }