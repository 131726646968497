<template>
  <v-row
    no-gutters
    class="color-bar"
    :style="cssVars"
  >
    <v-col
      v-for="(color, iColor) in showColors"
      :key="iColor"
      class="color-bar-item"
      :style="{
        background: color.color
      }"
    >
      <div
        v-if="
          showLabels.includes(iColor) &&
            color.label != null &&
            color.label != ''
        "
        class="color-bar-item-label"
      >
        {{ color.label }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ColorBar',

  props: {
    colors: {
      type: Array,
      default: () => []
    },
    maxLabels: {
      type: Number,
      default: undefined
    },
    height: {
      type: [Number, String],
      default: 24
    }
  },

  computed: {
    cssVars() {
      return {
        '--colorBarHeight': `${this.height}px`
      }
    },

    showColors() {
      const colors = this.colors.map(color => {
        let label = color.label
        const absLabel = Math.abs(label)

        if (absLabel >= 1000 || absLabel <= 0.01) {
          label = label.toExponential(2)
        } else if (absLabel >= 100) {
          label = Math.round(label * 10) / 10
        } else if (absLabel > 1) {
          label = Math.round(label * 100) / 100
        } else {
          label = Math.round(label * 1000) / 1000
        }

        return {
          ...color,
          label
        }
      })

      if (colors.length < 4) {
        return colors
      }

      if (colors.length % 2 === 0) {
        const fakeColorIndex = parseInt(colors.length / 4)
        const fakeColor = { ...colors[fakeColorIndex] }
        delete fakeColor.label

        colors.splice(fakeColorIndex + 1, 0, fakeColor)
      }

      return colors
    },

    showLabels() {
      if (
        !Array.isArray(this.showColors) ||
        !this.showColors.length ||
        this.maxLabels === 0
      ) {
        return []
      }

      const labels = this.showColors.map((_, iColor) => iColor)
      if (
        this.maxLabels == null ||
        labels.length <= this.maxLabels
      ) {
        return labels
      }
      if (
        this.maxLabels === 1 ||
        labels.length === 1
      ) {
        return [labels[0]]
      }
      if (this.maxLabels === 2) {
        return [labels.shift(), labels.pop()]
      }
      if (labels.length <= 3) {
        return labels
      }

      let maxLabels = this.maxLabels
      let gap = (labels.length - 1) / (maxLabels - 1)
      while (gap % 1 !== 0) {
        maxLabels -= 2
        gap = (labels.length - 1) / (maxLabels - 1)
      }
      const showLabels = Array.from({ length: maxLabels }, (_, iLabel) => {
        const val = labels[iLabel * gap]

        return val
      })

      return showLabels
    }
  }
}
</script>

<style lang="scss" scoped>
.color-bar {
  margin: 0 3em;
  height: calc(2 * var(--colorBarHeight));
  font-size: 0.8rem;
  font-family: monospace;

  &-item {
    position: relative;
    height: var(--colorBarHeight);

    &-label {
      position: absolute;
      top: var(--colorBarHeight);
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }
}
</style>
