<template>
  <v-row class="tab-item pa-3">
    <v-col>
      <ParameterInput
        v-for="(parameter, name) in leftParameters"
        :key="name"
        :parameter="parameter"
      />
    </v-col>

    <v-col>
      <ParameterInput
        v-for="(parameter, name) in rightParameters"
        :key="name"
        :parameter="parameter"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseParametersForm from '../../Base/BaseParametersForm'
import ParameterInput from '@/components/Simulation/ParameterInput'
import { cutObject } from '@/assets/js/utils'

export default {
  name: 'Physical',

  components: {
    ParameterInput
  },
  extends: BaseParametersForm, // 聯繫父層form的相關script

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  computed: {
    Physical() {
      return this.parameters?.Physical || {}
    },
    leftParameters() {
      return cutObject(this.Physical, 'Conductivity', 'SoilParticleDensity')
    },
    rightParameters() {
      return cutObject(this.Physical, 'SpecificYield', 'Macrodispersion')
    }
  }
}
</script>

<style lang="scss" scoped></style>
