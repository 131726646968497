<template>
  <!-- <div>
    <MapsetAnimationPlayer :mapset="mapsetNode" /> -->
  <v-list
    v-if="layerNodes.length"
    class="layer-list"
    dense
  >
    <v-list-item-group
      v-model="selected"
      color="primary"
    >
      <v-list-item
        v-for="(layerNode, iLayerNode) in showLayerNodes"
        :key="layerNode.uuid||iLayerNode"
        :value="layerNode"
        :ripple="false"
        :disabled="isLoadingEditSimParamFileNode"
        class="pl-0"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-row
              no-gutters
              align="center"
            >
              <v-col cols="auto">
                <v-btn
                  :ripple="false"
                  :disabled="!iLayerNode"
                  small
                  icon
                  @click.stop="moveToFront(layerNode)"
                >
                  <v-icon small>
                    mdi-chevron-up
                  </v-icon>
                </v-btn>
                <v-btn
                  :ripple="false"
                  :disabled="iLayerNode===showLayerNodes.length-1"
                  small
                  icon
                  @click.stop="moveToBack(layerNode)"
                >
                  <v-icon small>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </v-col>
              <!-- <v-col cols="auto">
                {{ `${iLayerNode + 1}`.padStart(2,'0') }}
              </v-col> -->
              <v-col cols="auto">
                <v-btn
                  icon
                  small
                  @click.stop="toggleVisible(layerNode)"
                >
                  <v-icon small>
                    {{ layerNode.visible?'mdi-eye':'mdi-eye-off' }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  title="定位"
                  icon
                  small
                  @click.stop="fitBounds(layerNode)"
                >
                  <v-icon small>
                    mdi-crosshairs-gps
                  </v-icon>
                </v-btn>
              </v-col>

              <!-- NOTE: 不設定寬度自動 truncate的話不能設cols="auto"
                   flex-basis不能設為auto-->
              <v-col
                class="text-truncate"
                :title="layerNode.name"
              >
                {{ layerNode.name }}
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          v-if="!isReadonly"
          class="flex-row align-center"
        >
          <v-btn
            :loading="layerNode.isLoading"
            icon
            small
            @click.stop="reloadLayerNode(layerNode)"
          >
            <v-icon small>
              mdi-cloud-refresh
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click.stop="removeNode(layerNode)"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
  <!-- </div> -->
</template>

<script>
// import MapsetAnimationPlayer from './AnimationPlayer/MapsetAnimationPlayer'

import { Node } from '@/models'
import { setProperties } from '@/models/utils'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'LayerList',

  // components: {
  //   MapsetAnimationPlayer
  // },

  props: {
    mapsetNode: {
      type: Node,
      default: null
    }
  },

  computed: {
    ...mapState({
      layerTree: state => state.map.layerTree,
      fileTree: state => state.files.fileTree,
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      isMapModelByRoute: 'map/isMapModelByRoute'
    }),

    selected: {
      get() {
        return this.currentLayerNode
      },
      set(newVal) {
        this.$store.commit('map/setState', {
          currentLayerNode: newVal
        })
      }
    },

    isMapModel() {
      return this.isMapModelByRoute(this.$route)
    },
    isReadonly() {
      return !this.isMapModel
    },
    isLoadingEditSimParamFileNode() {
      return this.currentLayerNode?.editSimParamFileNode?.isLoading === true
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    layerNodes() {
      const layerNodes = this.mapsetNode?.children

      if (!Array.isArray(layerNodes)) {
        return []
      }
      return layerNodes
    },
    showLayerNodes() {
      return [...this.layerNodes].reverse()
    }
  },

  methods: {
    setProperties,
    toggleVisible(node) {
      return this.$store.dispatch('map/toggleVisible', { node })
    },
    fitBounds(layerNode) {
      this.layerTree.fitBounds(layerNode?.mapBoundingBox)
    },
    removeNode(node) {
      return this.$store.dispatch('map/removeNode', { node })
    },
    reloadLayerNode(layerNode) {
      // layerNode找fileNode
      // 若是vtk來的glb, 只有原始vtk2glb的glb要重做vtk2glb, 另存新檔的glb只要reload glb
      // 目前只能用檔名判斷原始vtk2glb的glb
      let fileNode = this.fileTree.findNodeDF(this.fileTree.root, layerNode.uuid)
      if (fileNode?.is3DFile) {
        const vtkFileNode = this.fileTree.findNodeDF(this.fileTree.root, `${layerNode.fileName}.vtk`, 'name')
        if (vtkFileNode?.uuid === fileNode.settings.parentVtk) {
          fileNode = vtkFileNode
        }
      }

      if (!fileNode) {
        return
      }

      // vtk2glb 要檢查mapset permission要可以create
      if (
        fileNode.isVtkFile &&
        !this.getRoleByResourceId(this.project, fileNode.parent.uuid)?.create
      ) {
        return
      }

      layerNode.setProperties({
        isLoading: true
      })
      fileNode.setFileContent(null)
      fileNode.setProperties({
        hasLoaded: false
      })
      const storeAction = fileNode.isVtkFile
        ? 'createGlbFile'
        : 'fetchFileContent'
      return this.$store.dispatch(`files/${storeAction}`, {
        project: this.project,
        fileNode
      })
        .then(fileNodeWithContent => {
          this.$emit('fileContentLoaded', {
            fileNode: fileNodeWithContent
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          layerNode.setProperties({
            isLoading: false
          })
        })
    },
    moveToFront(node) {
      const beforeNode = this.layerNodes[node.index + 2]

      this.$store.dispatch('map/moveNode', {
        node,
        beforeNode
      })
    },
    moveToBack(node) {
      const beforeNode = this.layerNodes[node.index - 1]

      if (!beforeNode) {
        return
      }

      this.$store.dispatch('map/moveNode', {
        node,
        beforeNode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.layer-list {
  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  &.v-list--dense .v-list-item .v-list-item__title,
  &.v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 1rem;
    font-weight: normal;
  }

  .v-list-item {
    padding: 0 0.625rem;
    min-height: 30px;

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__action {
      margin: 0;
    }
  }
}
</style>
