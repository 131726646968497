<template>
  <div>
    <slot
      name="activator"
      :on="vOn"
    />
    <v-snackbar
      ref="snackbar"
      :timeout="-1"
      :value="isSlicing"
      color="primary"
      shaped
      top
      outlined
    >
      <v-row
        no-gutters
        align="center"
      >
        <v-col class="snack-draggable text-subtitle-1">
          {{ $t('page.map.slice_title') }}
        </v-col>
      </v-row>
      <v-row
        class="mt-2"
        no-gutters
        align="center"
      >
        <v-col cols="auto">
          <v-checkbox
            v-model="merge"
            class="mt-0 pt-0"
            hide-details="auto"
            dense
          >
            <template #label>
              <div class="grey--text text--darken-2 text-body-2">
                {{ $t('page.map.slice_merge_meshes') }}
              </div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row
        class="mt-2"
        no-gutters
        align="center"
      >
        <v-col
          v-if="currentLayerNode.meshSliceGroundYZ"
          cols="12"
        >
          <v-slider
            v-model="positionX"
            class="align-center"
            label="X"
            :min="0"
            :max="100"
            hide-details="auto"
          >
            <template #append>
              <v-text-field
                v-model="positionX"
                class="mt-0 pt-0"
                type="number"
                suffix="%"
                dense
                :min="0"
                :max="100"
                hide-details="auto"
                style="width: 60px;"
              />
            </template>
          </v-slider>
        </v-col>
        <v-col
          v-if="currentLayerNode.meshSliceGroundXZ"
          cols="12"
        >
          <v-slider
            v-model="positionY"
            class="align-center"
            label="Y"
            :min="0"
            :max="100"
            hide-details="auto"
          >
            <template #append>
              <v-text-field
                v-model="positionY"
                class="mt-0 pt-0"
                type="number"
                suffix="%"
                dense
                :min="0"
                :max="100"
                hide-details="auto"
                style="width: 60px;"
              />
            </template>
          </v-slider>
        </v-col>
        <v-col
          v-if="currentLayerNode.meshSliceGroundXY"
          cols="12"
        >
          <v-slider
            v-model="positionZ"
            class="align-center"
            label="Z"
            :min="0"
            :max="100"
            hide-details="auto"
          >
            <template #append>
              <v-text-field
                v-model="positionZ"
                class="mt-0 pt-0"
                type="number"
                suffix="%"
                dense
                :min="0"
                :max="100"
                hide-details="auto"
                style="width: 60px;"
              />
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row
        class="mt-2"
        no-gutters
        justify="end"
        align="center"
      >
        <v-col cols="auto">
          <v-btn
            color="primary"
            small
            :disabled="!canStartSlice"
            @click="startSlice"
          >
            {{ $t('start') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-btn
        color="error"
        text
        x-small
        icon
        style="position: absolute; top: 0.5em; right: 0.5em;"
        @click="cancelSlice(currentLayerNode)"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Slice',

  data: () => ({
    merge: true
  }),

  computed: {
    ...mapState({
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    isSlicing: {
      get() {
        return this.$store.state.postprocess.slice.isSlicing
      },
      set(boolean) {
        this.$store.commit('postprocess/slice/setState', {
          isSlicing: boolean
        })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },

    vOn() {
      return {
        click: this.prepareSlice
      }
    },

    canStartSlice() {
      return !!this.currentLayerNode?.sliceHelpMeshes
    },

    positionX: {
      get() {
        const meshSliceGroundYZ = this.currentLayerNode.meshSliceGroundYZ
        const boundingBox = this.currentLayerNode.meshParent.getBoundingInfo().boundingBox
        const boundingBoxSizeX = this.currentLayerNode.boundingBoxSize.x
        const position = meshSliceGroundYZ.position?.x - boundingBox.minimumWorld.x

        return Math.round((100 * position) / boundingBoxSizeX)
      },
      set(newVal) {
        const meshSliceGroundYZ = this.currentLayerNode.meshSliceGroundYZ
        const boundingBox = this.currentLayerNode.meshParent.getBoundingInfo().boundingBox
        const boundingBoxSizeX = this.currentLayerNode.boundingBoxSize.x
        const ratio = newVal / 100
        const newPosition = boundingBoxSizeX * ratio + boundingBox.minimumWorld.x
        meshSliceGroundYZ.position.x = newPosition
        this.currentLayerNode.render()
      }
    },

    positionY: {
      get() {
        const meshSliceGroundXZ = this.currentLayerNode.meshSliceGroundXZ
        const boundingBox = this.currentLayerNode.meshParent.getBoundingInfo().boundingBox
        const boundingBoxSizeY = this.currentLayerNode.boundingBoxSize.y
        const position = meshSliceGroundXZ.position?.y - boundingBox.minimumWorld.y

        return Math.round((100 * position) / boundingBoxSizeY)
      },
      set(newVal) {
        const meshSliceGroundXZ = this.currentLayerNode.meshSliceGroundXZ
        const boundingBox = this.currentLayerNode.meshParent.getBoundingInfo().boundingBox
        const boundingBoxSizeY = this.currentLayerNode.boundingBoxSize.y
        const ratio = newVal / 100
        const newPosition = boundingBoxSizeY * ratio + boundingBox.minimumWorld.y
        meshSliceGroundXZ.position.y = newPosition
        this.currentLayerNode.render()
      }
    },

    positionZ: {
      get() {
        const meshSliceGroundXY = this.currentLayerNode.meshSliceGroundXY
        const boundingBox = this.currentLayerNode.meshParent.getBoundingInfo().boundingBox
        const boundingBoxSizeZ = this.currentLayerNode.boundingBoxSize.z
        const position = meshSliceGroundXY.position?.z - boundingBox.minimumWorld.z

        return Math.round((100 * position) / boundingBoxSizeZ)
      },
      set(newVal) {
        const meshSliceGroundXY = this.currentLayerNode.meshSliceGroundXY
        const boundingBox = this.currentLayerNode.meshParent.getBoundingInfo().boundingBox
        const boundingBoxSizeZ = this.currentLayerNode.boundingBoxSize.z
        const ratio = newVal / 100
        const newPosition = boundingBoxSizeZ * ratio + boundingBox.minimumWorld.z
        meshSliceGroundXY.position.z = newPosition
        this.currentLayerNode.render()
      }
    }
  },

  watch: {
    currentLayerNode: {
      handler(newVal, oldVal) {
        if (this.isSlicing && oldVal && !oldVal.isSlicing) {
          this.cancelSlice(oldVal)
        }
      }
    }
  },

  beforeDestroy() {
    const layerNode = this.currentLayerNode

    if (this.isSlicing && layerNode && !layerNode.isSlicing) {
      this.cancelSlice(layerNode)
    }
  },

  methods: {
    cancelSlice(layerNode) {
      layerNode.endSlice()
      this.isSlicing = false
    },
    prepareSlice() {
      const layerNode = this.currentLayerNode
      if (!layerNode) {
        return
      }
      layerNode.startSlice()
      this.isSlicing = true
    },
    startSlice() {
      const layerNode = this.currentLayerNode
      if (!layerNode) {
        return
      }
      const sliceOrigin = layerNode.endSlice()
      this.$store.dispatch('postprocess/slice/start', {
        project: this.project,
        glbLayerNode: layerNode,
        origin: sliceOrigin,
        merge: this.merge
      })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })

          return Promise.reject(error)
        })

      this.isSlicing = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
