<template>
  <div class="d-flex flex-column">
    <v-tabs
      v-model="tab"
      class="flex-grow-0"
      background-color="transparent"
    >
      <v-tab
        v-for="(layer, iLayer) in layers"
        :key="iLayer"
      >
        {{ layer[2] }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="flex-grow-1"
    >
      <v-tab-item
        v-for="(layer, iLayer) in layers"
        :key="iLayer"
        :reverse-transition="false"
        :transition="false"
        eager
      >
        <v-card
          color="grey lighten-3"
          :elevation="0"
          height="100%"
        >
          <FeatureInfoForm
            ref="forms"
            :loading="loading"
            :feature="feature"
            :properties="layer[1]"
            :layer-label="layer[2]"
            :multi-layer-style2d-properties="style2dProperties"
            v-on="$listeners"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import FeatureInfoForm from './FeatureInfoForm.vue'

import {
  SYSTEM_PREFIX_PROPS,
  STYLE2D_KEYS,
  FEATURE_MULTI_LAYER
} from '@/models/utils'

export default {
  name: 'FeatureMultiLayerInfo',

  components: {
    FeatureInfoForm
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    feature: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    tab: 0
  }),

  computed: {
    layers() {
      if (!this.feature) {
        return []
      }

      return FEATURE_MULTI_LAYER.getLayerProperties(this.feature)
    },
    paintType() {
      return this?.feature?.layer?.type
    },
    style2dProperties() {
      // FIXME: 目前使用FeatureInfoForm.vue內一模一樣的code

      const targetLayerType = this.paintType
      const properties = this.feature.properties // style2D properties

      return Object.values(STYLE2D_KEYS)
        .filter(({ layerType }) => layerType.includes(targetLayerType))
        .map(({ key, defaultValue, dataType, attrs, options = [] }) => ({
          editable: true,
          style2dKey: key,
          propertyKey: SYSTEM_PREFIX_PROPS && key.replace(SYSTEM_PREFIX_PROPS, '#'),
          value: properties[key] || defaultValue,
          options: options.map(o => ({
            ...o,
            text: this.$t(o.text)
          })),
          dataType,
          attrs
        }))
    }
  },

  watch: {
    feature: {
      handler() {
        this.tab = 0
      }
    }
  },

  methods: {
    validate() {
      if (!Array.isArray(this.$refs.forms)) {
        return true
      }

      return Promise.all(
        this.$refs.forms.map(form => form.validate())
      )
        .then(results => {
          const firstNonValidTabIndex = results.indexOf(false)
          if (firstNonValidTabIndex > -1) {
            this.tab = firstNonValidTabIndex
          }
          return results.every(Boolean)
        })
    },
    resetValidation() {
      if (!Array.isArray(this.$refs.forms)) {
        return
      }

      this.$refs.forms.forEach(form => form.resetValidation())
    },
    getNewStyle2dProperties() {
      // 提供給外層使用, 取得內層新的資料
      return this.$refs.forms[0].getNewStyle2dProperties()
    },
    getNewProperties() {
      // 提供給外層使用, 取得內層新的資料
      return Object.fromEntries(
        this.layers.map((layer, iLayer) => {
          const newProperties = this.$refs.forms[iLayer].getNewProperties()

          return [
            layer[0],
            newProperties
          ]
        })
      )
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-window__container {
    height: 100%;

    .v-window-item {
      height: 100%;
    }
  }
}
</style>
