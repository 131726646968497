<template>
  <v-card
    color="grey lighten-3"
    :elevation="0"
    height="100%"
  >
    <v-row
      class="
          draggable
          px-8
          py-3
          bg-ease
          flex-grow-0
        "
      no-gutters
    >
      <v-col class="step-title white--text">
        <!-- {{ title }} -->
      </v-col>
    </v-row>
    <FeatureMultiLayerInfo
      v-if="isMultiLayer"
      ref="form"
      :loading="isLoading"
      :feature="feature"
      :style="{
        height: roleFileNode.update
          ?'calc(100% - 75px)'
          :'calc(100% - 24px)'
      }"
      v-on="$listeners"
    />
    <FeatureInfoForm
      v-else
      ref="form"
      :loading="isLoading"
      :feature="feature"
      :properties="(feature && feature.properties) || null"
      :style="{
        height: roleFileNode.update
          ?'calc(100% - 75px)'
          :'calc(100% - 24px)'
      }"
      v-on="$listeners"
    />

    <v-card-actions
      v-if="roleFileNode.update"
      class="pa-3 px-5"
    >
      <v-btn
        class="action-btn"
        rounded
        color="primary"
        :loading="isLoading"
        :disabled="!roleFileNode.update"
        @click="submit"
      >
        {{ $t('update') }}
      </v-btn>
      <v-btn
        class="action-btn ml-5"
        rounded
        :disabled="isLoading"
        @click="$emit('cancel')"
      >
        {{ $t('cancel') }}
      </v-btn>

      <v-spacer />

      <AddCommonPropertyDialog
        v-if="feature"
        :feature="feature"
        @cancel="cancel"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            class="action-btn"
            rounded
            color="secondary"
            :disabled="isLoading"
            v-on="on"
          >
            {{ $t('add_common_property') }}
          </v-btn>
        </template>
      </AddCommonPropertyDialog>

      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import FeatureInfoForm from './FeatureInfoForm.vue'
import FeatureMultiLayerInfo from './FeatureMultiLayerInfo.vue'
import AddCommonPropertyDialog from './AddCommonPropertyDialog'

import {
  FEATURE_MULTI_LAYER
} from '@/models/utils'

import { roleRoot } from '@/store/modules/files/files'
import { mapGetters, mapState } from 'vuex'

import { uniqBy } from 'lodash'

export default {
  name: 'FeatureInfoCard',

  components: {
    FeatureInfoForm,
    FeatureMultiLayerInfo,
    AddCommonPropertyDialog
  },

  props: {
    feature: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    isLoading: false
  }),

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      layerTree: state => state.map.layerTree
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      map: 'map/map'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    },

    mapLayerId() {
      return this.feature?.layer?.id
    },
    fileNode() {
      return this.fileTree.findNodeBF(this.fileTree.root, this.mapLayerId)
    },
    layerNode() {
      if (!this.layerTree) {
        return
      }
      return this.layerTree.findNodeBF(this.layerTree.root, this.mapLayerId)
    },
    isMultiLayer() {
      return FEATURE_MULTI_LAYER.is(this.feature)
    },
    roleFileNode() {
      if (!this.project) {
        return {}
      }

      if (this.project.isOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.fileNode?.uuid) || {}
    }
  },

  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async submit() {
      const isValid = await this.$refs.form.validate()

      if (!isValid) {
        return
      }

      this.isLoading = true
      // try catch 是為了讓exception可以結束loading
      try {
        const sourceId = this.feature.source

        // 取得feature id的方法, 用id找到要更新的feature
        // querySourceFeatures會把feature根據gemoetry切成片段(multi系列)
        // 用id取uniq, 再用原始資料的geometry做updateData
        // feature id是feature的陣列index
        const features = uniqBy(
          this.map.querySourceFeatures(sourceId, {
            sourceLayer: this.mapLayerId
          }),
          'id'
        ).sort((a, b) => a.id > b.id)
        const feature = features.find(f => f.id === this.feature.id)
        const oldData = this.fileNode.fileContent
        const newStyle2dProperties = this.$refs.form.getNewStyle2dProperties()
        const newProperties = this.$refs.form.getNewProperties()

        const newData = {
          ...oldData,
          features: features.map(({ id, properties }) => {
          // 要有id的features, 判斷要更新的feature
          // multi系列的geometry在features裡面只是片段
          // 要用切片之前的geometry
            const oldFeature = oldData.features[id] // ease feature

            if (id !== feature.id) {
              return {
                ...oldFeature,
                properties
              }
            }

            if (!FEATURE_MULTI_LAYER.is(oldFeature)) {
              return {
                ...oldFeature,
                properties: {
                  ...newStyle2dProperties,
                  ...newProperties
                }
              }
            }

            return {
              ...oldFeature,
              properties: {
                ...properties,
                ...newStyle2dProperties,
                ...newProperties
              }
            }
          })
        }

        this.map.getSource(sourceId).setData(newData)
        this.fileNode.setFileContent(newData)
        this.layerNode.source.setData(newData)
        return this.$store.dispatch('files/updateMaplayerData', {
          project: this.project,
          fileNode: this.fileNode,
          jsonContent: newData
        })
          .then(() => {
            this.cancel()
          })
          .catch(error => {
            this.$store.dispatch('snackbar/showError', {
              content: error
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      } catch (error) {
        this.isLoading = false
        this.$store.dispatch('snackbar/showError', {
          content: error
        })
        throw error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step-title {
  font-size: 1.125rem;
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
