<template>
  <v-form
    ref="form"
    v-model="isValid"
    :disabled="isLoading"
    style="height: 100%;"
    :style="{
      '--table-wrapper-height': roleFileNode.update
        ?'calc(100% - 28px)'
        :'100%'
    }"
  >
    <v-row
      class="flex-nowrap"
      no-gutters
      style="height: 100%;"
    >
      <v-col style=" overflow-y: auto; max-height: 100%;flex-basis: 500px;">
        <v-data-table
          class="property-table pl-3 rounded-0"
          :loading="isLoading"
          :headers="tableHeaders"
          :items="showProperties"
          :items-per-page="-1"
          :item-class="() => 'property-tr'"
          dense
          fixed-header
          hide-default-footer
        >
          <template #[`item.propertyKey`]="{ item }">
            <v-input
              v-if="item.isFeatureProperties && roleFileNode.update"
              :value="item.propertyKey"
              hide-details="auto"
              :rules="rulesPropertyKey"
            >
              <FeatureEditer
                v-model="item.propertyKey"
                :disabled="isLoading"
                :readonly="!roleFileNode.update"
              />
            </v-input>
            <span v-else>{{ item.propertyKey }}</span>
          </template>
          <template #[`item.value`]="{ item }">
            <FeatureCalculatorDialog
              v-if="item.computable"
              :geojson="feature"
              :paint-type="paintType"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="action-btn"
                  rounded
                  color="secondary"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-calculator
                  </v-icon>
                  {{ $t('calculate') }}
                </v-btn>
              </template>
            </FeatureCalculatorDialog>
            <FeatureEditer
              v-if="item.editable"
              v-model="item.value"
              :items="(Array.isArray(item.options) && item.options) || []"
              :type="item.dataType"
              :disabled="isLoading"
              :readonly="!roleFileNode.update"
              v-bind="item.attrs || {}"
            />
            <span v-else>{{ item.value }}</span>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="item.isFeatureProperties"
              icon
              small
              color="error"
              @click="removeProperty(item)"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <template #footer>
            <v-row
              v-if="roleFileNode.update"
              no-gutters
              justify="end"
              align="center"
            >
              <v-btn
                text
                small
                color="primary"
                @click="addProperty"
              >
                {{ $t('add_property') }}
                <v-icon small>
                  mdi-plus
                </v-icon>
              </v-btn>

              <ModflowParametersDialog
                v-if="
                  [
                    SIM_MODEL.MODFLOW,
                    SIM_MODEL.FEMWATER_MESH
                  ].includes(model)
                "
                :layer-node="layerNode"
                :feature-index="featureIndex"
                :layer-index="layerIndex"
              >
                <template #activator="{ on, attrs}">
                  <v-tooltip
                    :disabled="!!editSimParamFileNode"
                    top
                  >
                    <template #activator="{ on:onTooltip, attrs:attrsTooltip }">
                      <div
                        v-bind="attrsTooltip"
                        v-on="onTooltip"
                      >
                        <v-btn
                          v-bind="attrs"
                          text
                          small
                          color="secondary"
                          :disabled="!editSimParamFileNode"
                          v-on="on"
                        >
                          {{ $t('simulation_parameter_abbreviation') }}
                          <v-icon small>
                            mdi-tune
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t('page.modflow.tip_select_param_file_first') }}</span>
                  </v-tooltip>
                </template>
              </ModflowParametersDialog>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        class="white ml-3"
        cols="5"
        style=" overflow-y: auto; max-height: 100%;flex-basis: 348px;"
      >
        <v-progress-linear
          v-if="isLoadingDashboards"
          absolute
          color="primary"
          indeterminate
        />
        <IssueCard
          v-else-if="hasNoData"
          :message="$t('page.map.no_dashboard')"
          not-found
          img-width="70%"
          style="margin-top: 60px;"
        />
        <v-hover
          v-for="(dashboard, iDashboard) in dashboards"
          v-else
          v-slot="{ hover }"
          :key="`${mapLayerId}-${iDashboard}`"
        >
          <v-card
            class="dashboard-card mb-7 mx-9"
            :class="{
              'primary--text': !hover,
              primary: hover,
              'mt-7': iDashboard === 0
            }"
            :dark="hover"
            height="100px"
            outlined
            style="cursor: pointer;"
            :disabled="!dashboard.panels.length"
            @click="$emit('click:dashboard', dashboard, properties, layerLabel)"
          >
            <v-card-title>
              <div
                class="text-truncate"
                :title="dashboard.title"
                v-text="dashboard.title"
              />
            </v-card-title>
            <v-card-subtitle>
              <v-row
                v-if="dashboard.panels.length"
                class="dashboard-description"
                :title="dashboard.description"
                no-gutters
                v-text="dashboard.description"
              />
              <!-- tip no panels -->
              <v-row
                v-else
                lass="dashboard-description"
                no-gutters
              >
                {{ $t('page.map.no_panel') }}
              </v-row>
            </v-card-subtitle>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import FeatureEditer from '@/components/Map/FeatureViewer/FeatureEditer'
import IssueCard from '@/components/IssueCard'
import ModflowParametersDialog from '@/components/Simulation/Modflow/Parameters/ModflowParametersDialog.vue'
import FeatureCalculatorDialog from '@/components/Map/FeatureCalculator/FeatureCalculator.vue'

import { SIM_MODEL } from '@/models'
import {
  validateRequired,
  validateDuplicate
} from '@/assets/js/validate'
import {
  SYSTEM_PREFIX_PROPS,
  SYSTEM_PREFIX_MULTI_LAYER,
  STYLE2D_KEYS,
  FEATURE_MULTI_LAYER
} from '@/models/utils'
import { roleRoot } from '@/store/modules/files/files'
import { mapState, mapGetters } from 'vuex'

const tableHeaders = vm => [
  {
    text: vm.$t('property'),
    value: 'propertyKey'
  },
  {
    text: vm.$t('value'),
    value: 'value'
  },
  {
    text: '',
    value: 'actions',
    width: '3rem'
  }
]

const regPrefix = new RegExp(`^${SYSTEM_PREFIX_PROPS}|${SYSTEM_PREFIX_MULTI_LAYER}`, 'i')
const rulesPropertyKey = vm => [
  validateRequired,
  v => !regPrefix.test(v) || vm.$t('validate.system_reserved_words_no_used'),
  validateDuplicate(vm.featurePropertyKeys, null, 1)
]

export default {
  name: 'FeatureInfoForm',

  components: {
    FeatureEditer,
    IssueCard,
    ModflowParametersDialog,
    FeatureCalculatorDialog
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    feature: {
      type: Object,
      default: null
    },
    properties: {
      type: Object,
      default: null
    },
    layerLabel: {
      type: [String, Number],
      default: null
    },
    multiLayerStyle2dProperties: {
      type: Array,
      default: null
    }
  },

  data: vm => ({
    isValid: true,
    SIM_MODEL,
    featureProperties: []
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      fileTree: state => state.files.fileTree,
      layerTree: state => state.map.layerTree,
      isLoadingDashboards: state => state.dashboards.isLoadingDashboards
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      map: 'map/map',
      getDashboardsByMaplayerId: 'dashboards/getDashboardsByMaplayerId'
    }),
    rulesPropertyKey,

    tableHeaders() {
      if (this.roleFileNode.update) {
        return tableHeaders(this)
      }

      // viewer case
      return tableHeaders(this).slice(0, -1)
    },
    isLoading() {
      return this.loading
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    mapLayerId() {
      return this.feature?.layer?.id
    },
    fileNode() {
      return this.fileTree.findNodeBF(this.fileTree.root, this.mapLayerId)
    },
    layerNode() {
      if (!this.layerTree) {
        return
      }
      return this.layerTree.findNodeBF(this.layerTree.root, this.mapLayerId)
    },
    editSimParamFileNode() {
      return this.layerNode?.editSimParamFileNode
    },
    featureIndex() {
      return this.feature?.id
    },
    layerIndex() {
      return FEATURE_MULTI_LAYER.getLayerIndexByLayerLabel(this.feature, this.layerLabel)
    },
    roleFileNode() {
      if (!this.project) {
        return {}
      }

      if (this.project.isOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.fileNode?.uuid) || {}
    },
    hasNoData() {
      return !Array.isArray(this.dashboards) || !this.dashboards.length
    },
    dashboards() {
      return this.getDashboardsByMaplayerId(this.mapLayerId)
    },
    paintType() {
      return this?.feature?.layer?.type
    },
    canCalculate() {
      const allowPaintTypes = ['line', 'fill']

      return allowPaintTypes.includes(this.paintType)
    },
    calcProperties() {
      if (!this.canCalculate) {
        return []
      }

      const propertyKey = `#${
        this.paintType === 'fill'
        ? this.$t('polygon_area')
        : this.$t('line_string_distance')
      }`

      return [
        {
          noSave: true,
          computable: true,
          propertyKey
        }
      ]
    },
    style2dProperties() {
      if (this.multiLayerStyle2dProperties) {
        return this.multiLayerStyle2dProperties
      }

      const targetLayerType = this.paintType
      const properties = this.feature.properties // style2D properties

      return Object.values(STYLE2D_KEYS)
        .filter(({ layerType }) => layerType.includes(targetLayerType))
        .map(({ key, defaultValue, dataType, attrs, options = [] }) => ({
          editable: true,
          style2dKey: key,
          propertyKey: SYSTEM_PREFIX_PROPS && key.replace(SYSTEM_PREFIX_PROPS, '#'),
          value: properties[key] || defaultValue,
          options: options.map(o => ({
            ...o,
            text: this.$t(o.text)
          })),
          dataType,
          attrs
        }))
    },
    featurePropertyKeys() {
      return this.featureProperties.map(p => p.propertyKey)
    },
    showProperties() {
      if (!this.feature) return []

      return [
        ...this.calcProperties,
        ...this.style2dProperties,
        ...this.featureProperties
      ]
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    },
    properties: {
      handler() {
        this.formatFeatureProperties()
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    validate() {
      if (!this.$refs.form) {
        return
      }

      return this.$refs.form.validate()
    },
    resetValidation() {
      if (!this.$refs.form) {
        return
      }

      this.$refs.form.resetValidation()
    },
    formatFeatureProperties() {
      // feature properties data structure
      if (!this.feature) {
        this.featureProperties = []
        return
      }

      const convertPropVal = (val) => {
        if (val === 'null' || val == null) {
          return
        }
        let result
        switch (typeof val) {
          case 'object':
            result = JSON.stringify(val)
            break
          case 'number':
            result = val
            break

          default:
            result = String(val)
            break
        }
        return result
      }

      this.featureProperties = Object.entries(this.properties)
        .filter(([propertyKey]) => !SYSTEM_PREFIX_PROPS || !propertyKey.startsWith(SYSTEM_PREFIX_PROPS))
        .map(([propertyKey, val]) => ({
          editable: true,
          isFeatureProperties: true,
          propertyKey,
          value: convertPropVal(val)
        }))
        .sort((a, b) => a.propertyKey.localeCompare(b.propertyKey))
    },
    addProperty() {
      this.featureProperties.unshift({
        editable: true,
        isFeatureProperties: true,
        propertyKey: '',
        value: ''
      })

      this.resetValidation()
    },
    removeProperty(property) {
      this.featureProperties = this.featureProperties.filter(p => p !== property)
    },
    getNewStyle2dProperties() {
      // 提供給外層使用, 取得內層新的資料
      return Object.fromEntries(
        this.style2dProperties.map(({ style2dKey, propertyKey, value }) => [
          style2dKey || propertyKey,
          value
        ])
      )
    },
    getNewProperties() {
      // 提供給外層使用, 取得內層新的資料
      return Object.fromEntries(
        this.featureProperties.map(({ propertyKey, value }) => [
          propertyKey,
          value
        ])
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.property-table {
  height: 100%;

  ::v-deep .v-data-table__wrapper {
    height: var(--table-wrapper-height);
  }

  ::v-deep .v-data-table-header {
    background-color: white;

    tr th {
      font-size: 1rem;
      font-weight: bold;
      color: #343843;
    }
  }

  ::v-deep .property-tr {
    &:nth-of-type(odd) {
      background-color: #f0f3f6;
    }

    td {
      padding-top: 16px;
      padding-bottom: 16px;
      max-width: 120px;
      font-size: 1rem;
      overflow-wrap: break-word;

      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}

.dashboard-card {
  border: 1px solid $color-primary;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);

  .dashboard-description {
    @include multi-line-trancate(2);
  }
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
