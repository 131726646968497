var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"870","content-class":"modflow-params-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"min-height":"400"}},[_c('v-card-title',[_c('v-row',{staticClass:"px-3 my-0 py-1",attrs:{"align":"end"}},[_c('v-col',{staticClass:"modflow-divider pa-0 mb-3 mr-11"},[(_vm.editFileNode)?[_vm._l((_vm.editFileNode.breadcrumbs),function(breadcrumb,iBreadcrumb){return [_c('span',{key:iBreadcrumb,staticClass:"text-no-wrap",staticStyle:{"font-size":"0.9rem"}},[_vm._v(_vm._s(breadcrumb))]),(iBreadcrumb < _vm.editFileNode.breadcrumbs.length - 1)?_c('v-icon',{key:(iBreadcrumb + "-divder"),attrs:{"color":"primary","small":""}},[_vm._v(" mdi-chevron-right ")]):_vm._e()]})]:_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('new_file')))])],2),(_vm.hasFeature)?_c('v-col',{staticClass:"pl-0 mr-4",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"modflow-btn",attrs:{"color":"secondary","small":"","loading":_vm.isLoading},on:{"click":_vm.clearParameters}},[_vm._v(" "+_vm._s(_vm.$t('use_default'))+" ")])],1):(!!_vm.editModelFileNode)?_c('v-col',{staticClass:"pl-0 mr-4",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"modflow-btn",attrs:{"color":"secondary","small":"","disabled":_vm.isLoading},on:{"click":_vm.applyModalParameters}},[_vm._v(" "+_vm._s(_vm.$t('apply_global_params'))+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.isEdit && _vm.layerNode)?_c('SaveAsDialog',{attrs:{"file-content":_vm.modflowParams,"parent-maplayer-uuid":_vm.layerNode.s3Uuid,"edit-file-node":_vm.editFileNode},on:{"update:editFileNode":function($event){_vm.editFileNode=$event},"update:edit-file-node":function($event){_vm.editFileNode=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"modflow-btn",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('save_as_new_file'))+" ")])]}}],null,false,391296352)}):_c('v-tooltip',{attrs:{"top":"","nudge-top":"-15px","disabled":_vm.roleEditFileNode.update},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"modflow-btn",attrs:{"color":"primary","small":"","loading":_vm.isLoading,"disabled":!_vm.roleEditFileNode.update},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('permission_denied')))])])],1)],1)],1),_c('v-card-text',[(_vm.editParameters)?_c(_vm.currentParametersComponent,{tag:"component",attrs:{"parameters":_vm.editParameters}}):_vm._e()],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),(!_vm.hasFeature)?_c('v-btn',{staticClass:"action-btn",attrs:{"rounded":"","disabled":_vm.isLoading},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]):_vm._e(),(_vm.isEdit)?_c('v-btn',{staticClass:"action-btn ml-12",attrs:{"color":"secondary","rounded":"","disabled":_vm.isLoading},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }