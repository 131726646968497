
import interact from 'interactjs'

export default (className, options) => {
  const {
    restrictSize = {},
    onstart,
    onmove,
    onend,
    edges = { left: true, right: true, top: true, bottom: true }
  } = options

  interact(className)
    .resizable({
    // resize from all edges and corners
      edges,

      listeners: {
        start(event) {
          if (typeof onstart === 'function') {
            onstart(event)
          }
        },
        move(event) {
          if (typeof onmove === 'function') {
            onmove(event)
          }

          const target = event.target
          let x = (parseFloat(target.getAttribute('data-x')) || 0)
          let y = (parseFloat(target.getAttribute('data-y')) || 0)

          // update the element's style
          target.style.width = event.rect.width + 'px'
          target.style.height = event.rect.height + 'px'

          // translate when resizing from top or left edges
          x += event.deltaRect.left
          y += event.deltaRect.top

          target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

          target.setAttribute('data-x', x)
          target.setAttribute('data-y', y)
        },
        end(event) {
          if (typeof onend === 'function') {
            onend(event)
          }
        }
      },
      modifiers: [
      // keep the edges inside the parent
        // interact.modifiers.restrictEdges({
        //   outer: 'parent'
        // }),

        // minimum size
        interact.modifiers.restrictSize(restrictSize)
      ],

      inertia: true
    })
}
