<template>
  <v-row class="tab-item pa-3">
    <v-col>
      <ParameterInput
        v-for="(parameter, name) in leftParameters"
        :key="name"
        :parameter="parameter"
      />
    </v-col>

    <v-col>
      <ParameterInput
        v-for="(parameter, name) in rightParameters"
        :key="name"
        :parameter="parameter"
      />

      <v-row
        no-gutters
        style="width: 86%;"
      >
        <v-btn
          color="#14a83b"
          :disabled="disabledAssignBtn"
          rounded
          block
          @click="assignValue(AquiferElevationsCalibrationData.StartingHead, PrescribedHead)"
        >
          Turn Starting Head to Prescribed Head
        </v-btn>
      </v-row>

      <v-row
        no-gutters
        style="width: 86%;"
      >
        <v-btn
          color="#14a83b"
          :disabled="disabledAssignBtn"
          rounded
          block
          @click="assignValue(PrescribedHead, AquiferElevationsCalibrationData.StartingHead)"
        >
          Turn Prescribed Head to Starting Head
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import BaseParametersForm from '../../Base/BaseParametersForm'
import ParameterInput from '@/components/Simulation/ParameterInput'
import { cutObject } from '@/assets/js/utils'
import { getProp } from '@/models/Simulation/Modflow/utils'

export default {
  name: 'AquiferElevationsCalibrationData',

  components: {
    ParameterInput
  },
  extends: BaseParametersForm, // 聯繫父層form的相關script

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  computed: {
    AquiferElevationsCalibrationData() {
      return this.parameters?.AquiferElevationsCalibrationData || {}
    },
    leftParameters() {
      return cutObject(this.AquiferElevationsCalibrationData, 'AquiferElevation', 'AquiferElevation')
    },
    rightParameters() {
      return cutObject(this.AquiferElevationsCalibrationData, 'StartingHead', 'StartingHead')
    },
    PrescribedHead() {
      return this.parameters?.SourcesAndSinks?.PrescribedHead
    },

    disabledAssignBtn() {
      if (!this.PrescribedHead) return true

      return (
        !this.PrescribedHead ||
        this.PrescribedHead.apply === false ||
        this.PrescribedHead.disabled ||
        this.PrescribedHead.rootDisabled ||
        this.PrescribedHead.case !== 'Constant'
      )
    }
  },

  methods: {
    assignValue(a, b) {
      const aProp = getProp(a)
      const bProp = getProp(b)

      if (!aProp || !bProp) return

      aProp.value = bProp.value
    }
  }
}
</script>

<style lang="scss" scoped></style>
