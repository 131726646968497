var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"width":"600","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),_vm.menuVOn),[_vm._v(" "+_vm._s(_vm.$t('simulation_parameter_abbreviation'))+" ")])]},{"on":_vm.menuVOn,"attrs":attrs,"edit":!!_vm.editFileNode})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"py-0"},[_c('FileSelect',{attrs:{"value":_vm.editFileNode,"simulation-model-uuid":_vm.model,"parent-maplayer-uuid":_vm.currentLayerNode && _vm.currentLayerNode.s3Uuid,"filter-parent-maplayer":""},on:{"input":_vm.onSelectFile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","large":"","block":"","loading":_vm.editFileNode&&_vm.editFileNode.isLoading===true}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])]}}])}),_c('v-btn',{attrs:{"text":"","large":"","block":""},on:{"click":_vm.createNewFile,"mouseup":_vm.closeMenu}},[_vm._v(" "+_vm._s(_vm.$t('new'))+" ")]),_c('FileSelect',{attrs:{"value":_vm.editFileNode,"permissions":['update']},on:{"input":_vm.onLoadFile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","large":"","block":"","loading":_vm.editFileNode&&_vm.editFileNode.isLoading===true}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('load'))+" ")])]}}])})],1)],1),_c('ModflowParametersDialog',{attrs:{"layer-node":_vm.currentLayerNode},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }