var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"width":"600","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c('v-badge',{attrs:{"value":!!_vm.editModelFileNode,"bordered":"","icon":"mdi-pencil","color":"secondary","overlap":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"bg-ease",attrs:{"rounded":"","dark":""}},'v-btn',attrs,false),_vm.menuVOn),[_c('v-icon',[_vm._v("mdi-tune")])],1)],1)]},{"on":_vm.menuVOn,"attrs":attrs,"edit":!!_vm.editModelFileNode})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"py-0"},[_c('FileSelect',{attrs:{"value":_vm.editModelFileNode,"simulation-model-uuid":_vm.model,"filter-parent-maplayer":""},on:{"input":_vm.onSelectFile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","large":"","block":"","loading":_vm.editModelFileNode &&
              _vm.editModelFileNode.isLoading === true}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])]}}])}),_c('v-btn',{attrs:{"text":"","large":"","block":""},on:{"click":_vm.createNewFile,"mouseup":_vm.closeMenu}},[_vm._v(" "+_vm._s(_vm.$t('new'))+" ")]),_c('FileSelect',{attrs:{"value":_vm.editModelFileNode,"permissions":['update']},on:{"input":_vm.onLoadFile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","large":"","block":"","loading":_vm.editModelFileNode &&
              _vm.editModelFileNode.isLoading === true}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('load'))+" ")])]}}])})],1),(_vm.model === _vm.SIM_MODEL.INTERPOLATING)?_c('InterpolatingModelDialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e(),(
      [
        _vm.SIM_MODEL.MODFLOW,
        _vm.SIM_MODEL.FEMWATER_MESH
      ].includes(_vm.model)
    )?_c('ModflowModelDialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }