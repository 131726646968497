<template>
  <!-- retain-focus 不在官方doc, 設為false點地圖時才不會focus trap在v-dialog -->
  <div>
    <v-dialog
      v-model="dialog"
      :transition="false"
      :retain-focus="false"
      content-class="layermgrctrl-dialog elevation-0"
      hide-overlay
      persistent
      max-width="400"
      width="30%"
      no-click-animation
    >
      <v-expansion-panels
        v-model="expansions"
        class="layermgrctrl-expansion-panels"
        multiple
        focusable
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            class="bg-ease white--text"
            hide-actions
          >
            {{ $t('page.map.manage_folders_title') }}
            <template #actions>
              <v-icon color="white">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <FolderList />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header
            class="bg-ease white--text"
            hide-actions
          >
            {{ $t('page.map.manage_mapsets_title') }}
            <template #actions>
              <v-icon color="white">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <MapsetList v-on="$listeners" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header
            class="bg-ease white--text"
            hide-actions
          >
            {{ $t('page.map.manage_functions_title') }}
            <template #actions>
              <v-icon color="white">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <LayerFunctions v-on="$listeners" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-dialog>

    <!-- LegendBarDialog 放在這, 避免檢閱模式消失 -->
    <template v-for="layerNode in layerNodesWithLegends">
      <template v-for="(legend, iLegend) in layerNode.legends">
        <LegendBarDialog
          v-if="Array.isArray(legend.legends)"
          :key="`${layerNode.uuid}-${iLegend}`"
          :value="legend.visible"
          :layer-node="layerNode"
          :legend="legend"
          @input="newVal => setProperties(legend, {
            visible: newVal
          })"
        />
      </template>
    </template>
  </div>
</template>

<script>
import FolderList from './FolderList'
import MapsetList from './MapsetList'
import LayerFunctions from './LayerFunctions'
import LegendBarDialog from './Legends/LegendBarDialog'

import { setProperties } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LayerMgrCtrl',

  components: {
    FolderList,
    MapsetList,
    LayerFunctions,
    LegendBarDialog
  },

  data: () => ({
    dialog: true,
    expansions: [0, 1, 2]
  }),

  computed: {
    ...mapState({
      currentFolderNode: state => state.map.currentFolderNode
    }),
    ...mapGetters({
      isLiteModelByRoute: 'map/isLiteModelByRoute'
    }),

    isLiteModel() {
      return this.isLiteModelByRoute(this.$route)
    },

    layerNodes() {
      const mapsetNodes = this.currentFolderNode?.children || []
      const layerNodes = mapsetNodes.reduce((acc, cur) => [
        ...acc,
        ...(cur?.children || [])
      ],
      [])

      return layerNodes
    },
    layerNodesWithLegends() {
      return this.layerNodes.filter(layerNode => layerNode.hasLegends)
    }
  },

  watch: {
    isLiteModel: {
      handler(newVal) {
        this.dialog = !newVal
      },
      immediate: true
    }
  },

  activated() {
    if (!this.dialog && !this.isLiteModel) {
      this.dialog = true
    }
  },

  mounted() {
    if (!this.dialog && !this.isLiteModel) {
      this.dialog = true
    }
  },

  methods: {
    setProperties
  }
}
</script>

<style lang="scss">
.layermgrctrl-dialog {
  position: absolute;
  top: $map-nav-height;
  left: 0;
  display: none;
  min-width: 320px;

  @media (min-width: 600px) {
    display: block;
  }
}
</style>

<style lang="scss" scoped>
.layermgrctrl-expansion-panels {
  .v-expansion-panel-header {
    padding: 8px 16px;
    min-height: 40px;
    font-size: 1.125rem;
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 8px 6px;
  }

  .v-expansion-panel {
    .v-expansion-panel-content {
      overflow-y: auto;
    }

    &:nth-of-type(1) {
      .v-expansion-panel-content {
        max-height: 210px;
      }
    }

    &:nth-of-type(2) {
      .v-expansion-panel-content {
        max-height: 460px;
      }
    }

    // &:nth-of-type(3){
    //   .v-expansion-panel-content{
    //     max-height: 100px;
    //   }
    // }
  }
}
</style>
