<template>
  <div>
    <v-menu
      v-model="menu"
      width="600"
      offset-x
    >
      <template #activator="{ attrs }">
        <slot
          name="activator"
          :on="menuVOn"
          :attrs="attrs"
          :edit="!!editFileNode"
        >
          <v-btn
            v-bind="attrs"
            color="primary"
            v-on="menuVOn"
          >
            {{ $t('simulation_parameter_abbreviation') }}
          </v-btn>
        </slot>
      </template>
      <v-list class="py-0">
        <FileSelect
          :value="editFileNode"
          :simulation-model-uuid="model"
          :parent-maplayer-uuid="currentLayerNode && currentLayerNode.s3Uuid"
          filter-parent-maplayer
          @input="onSelectFile"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              text
              large
              block
              :loading="editFileNode&&editFileNode.isLoading===true"
              v-on="on"
            >
              {{ $t('edit') }}
            </v-btn>
          </template>
        </FileSelect>
        <v-btn
          text
          large
          block
          @click="createNewFile"
          @mouseup="closeMenu"
        >
          {{ $t('new') }}
        </v-btn>
        <FileSelect
          :value="editFileNode"
          :permissions="['update']"
          @input="onLoadFile"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              text
              large
              block
              :loading="editFileNode&&editFileNode.isLoading===true"
              v-on="on"
            >
              {{ $t('load') }}
            </v-btn>
          </template>
        </FileSelect>
      </v-list>
    </v-menu>

    <!-- v-dialog不能放在v-menu裡面, v-if切換時會不知為何消失 -->
    <ModflowParametersDialog
      v-model="dialog"
      :layer-node="currentLayerNode"
    />
  </div>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect.vue'
import ModflowParametersDialog from './ModflowParametersDialog'

import { MAP_LAYERTYPE_GEOTYPE } from '@/store/modules/simulation/modules/modflow'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ModflowParametersMenu',

  components: {
    FileSelect,
    ModflowParametersDialog
  },

  data: (vm) => ({
    menuVOn: { click: vm.toggleMenu },
    menu: false,
    dialog: false
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    editFileNode: {
      get() {
        return this.currentLayerNode.editSimParamFileNode
      },
      set(newVal) {
        this.currentLayerNode.setProperties({
          editSimParamFileNode: newVal
        })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    targetMapset() {
      return this?.currentLayerNode?.parent
    }
  },

  methods: {
    toggleMenu() {
      if (this.editFileNode) {
        this.menu = false
        this.dialog = true

        return
      }

      this.menu = !this.menu
    },
    closeMenu() {
      this.menu = false
    },
    createNewFile() {
      this.dialog = true
      this.closeMenu()
    },
    onSelectFile(fileNode) {
      this.editFileNode = fileNode
      this.$store.dispatch('globalFiles/fetchFileContent', {
        project: this.project,
        fileNode
      })
        .then(() => {
          this.dialog = true
          this.closeMenu()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })
        })
    },
    onLoadFile(fileNode) {
      const layerNode = this.currentLayerNode
      this.editFileNode = fileNode
      this.$store.dispatch('globalFiles/fetchFileContent', {
        project: this.project,
        fileNode
      })
        .then(fileNodeWithContent => {
          const modflowParams = fileNodeWithContent.fileContent
          const layerGeoType = MAP_LAYERTYPE_GEOTYPE[layerNode.type]
          const layerNodeFetureCount = layerNode.source.geoJsonData.features.length

          if (
            modflowParams.type !== layerGeoType ||
            modflowParams.features.length !== layerNodeFetureCount
          ) {
            this.editFileNode = null
            return Promise.reject(new Error(this?.$t?.('api_errors.data_error')))
          }

          return this.$store.dispatch('globalFiles/updateMaplayer', {
            project: this.project,
            fileNode,
            data: {
              maplayer_type: 'SETTINGS_FILE',
              simulation_model_uuid: this.model,
              parent_maplayer_uuid: layerNode.s3Uuid
            }
          })
        })
        .then(() => {
          this.dialog = true
          this.closeMenu()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })

          return Promise.reject(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
