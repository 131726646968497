<template>
  <v-tabs
    v-model="tab"
    class="modflow-tabs v-tabs--hide-headers"
    dark
    background-color="#4a4e58"
    color="white"
    hide-slider
    fixed-tabs
    mandatory
    style="max-width: 400px;"
  >
    <v-tabs-items v-model="tab">
      <v-tab-item
        value="model"
        eager
        :transition="false"
        :reverse-transition="false"
      >
        <v-form
          ref="form"
          v-model="isValid"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model.number="parameters.nx"
                  type="number"
                  label="nx"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="parameters.ny"
                  type="number"
                  label="ny"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.number="parameters.nz"
                  type="number"
                  label="nz"
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
export default {
  name: 'ModflowModelForm',

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    tab: 'model',
    isValid: true
  })
}
</script>

<style lang="scss" scoped>
.modflow-tabs {
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
