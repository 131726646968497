<template>
  <div>
    <slot
      v-if="result==null"
      name="activator"
      :on="vOn"
    >
      <v-btn :on="vOn">
        <v-icon left>
          mdi-calculator
        </v-icon>
        {{ $t('calculate') }}
      </v-btn>
    </slot>
    <v-row
      v-else
      no-gutters
      align="center"
    >
      <v-col>
        <v-text-field
          :value="result"
          readonly
          dense
          hide-details="auto"
        >
          <template #append>
            <div class="mt-1">
              <span v-if="paintType==='fill'">m<sup>2</sup></span>
              <span v-else>km</span>
            </div>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        class="ml-2"
        cols="auto"
      >
        <v-btn
          icon
          small
          @click="copy(`${result}`)"
        >
          <v-icon small>
            mdi-content-copy
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import copyText from 'clipboard-copy'
import { length as turfLength, area as turfArea } from '@turf/turf'

export default {
  name: 'FeatureCalculator',

  props: {
    geojson: {
      type: Object,
      required: true
    },
    paintType: {
      type: String,
      required: true,
      validator(value) {
        return ['line', 'fill'].includes(value)
      }
    }
  },

  data: vm => ({
    result: null,
    unit: ''
  }),

  computed: {
    vOn() {
      return {
        click: this.calculateAreaOrDistance
      }
    }
  },

  methods: {
    copy(text) {
      return copyText(text)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_copied')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    },
    calculateAreaOrDistance() {
      const calc = this.paintType === 'fill' ? turfArea : turfLength

      this.result = calc(this.geojson)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
