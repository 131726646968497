<template>
  <v-container
    id="mapCtx"
    class="pa-0"
    fluid
    style="height: 100%;"
  >
    <keep-alive>
      <router-view
        name="nav"
        @fileContentLoaded="onFileContentLoaded"
      />
    </keep-alive>

    <v-overlay
      :opacity="0.9"
      :value="isLoading"
      z-index="9999"
    >
      <v-progress-circular
        indeterminate
        size="84"
      >
        {{ $t('loading') }}...
      </v-progress-circular>
    </v-overlay>

    <!-- multi-map instance solution -->
    <!-- <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        :key="$route.name||$route"
        v-model="map"
        name="map"
        :map-id="$route.name||'map'"
        :access-token="mapboxAccessToken"
        :center="center"
        :zoom="zoom"
        @load="onMapLoad"
      />
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      :key="$route.name||$route"
      v-model="map"
      name="map"
      :map-id="$route.name||'map'"
      :access-token="mapboxAccessToken"
      :center="center"
      :zoom="zoom"
      @load="onMapLoad"
    /> -->

    <MglMap
      ref="map"
      v-model="map"
      :access-token="mapboxAccessToken"
      :center="center"
      :zoom.sync="zoom"
      :disabled-styles="!isMapModel"
      :show-styles="!isLiteModel"
      :show-controls="!isLiteModel"
      @load="onMapLoad"
    />

    <!-- LayerMgrCtrl 檢閱模式可視性靠自己控制 -->
    <LayerMgrCtrl
      @fileContentLoaded="onFileContentLoaded"
    />

    <GlbMeshMgr
      v-if="!isLiteModel"
    />

    <SearchBar
      v-if="!isLiteModel"
    />

    <FeatureViewer v-model="showFeatureViewer" />

    <AnimationPlayer
      :folder="animationFolder"
      @close="animationFolder = null"
    />

    <DrawerView v-if="isDrawerModel && map" />

    <SimulationModelSelect
      v-if="isMapModel"
      class="d-none d-sm-flex"
    />
  </v-container>
</template>

<script>
import settings from '@/assets/js/settings.js'

import MglMap from '@/components/Map/MglMap'
import FeatureViewer from '@/components/Map/FeatureViewer/FeatureViewer'
import LayerMgrCtrl from '@/components/Map/LayerMgr/LayerMgrCtrl'
import GlbMeshMgr from '@/components/Map/GlbMeshMgr/GlbMeshMgr'
import SearchBar from '@/components/Map/SearchBar/SearchBar'
import AnimationPlayer from '@/components/Map/LayerMgr/AnimationPlayer/AnimationPlayer'
import DrawerView from '@/components/Map/Drawer/DrawerView'
import SimulationModelSelect from '@/components/Simulation/Model/SimulationModelSelect'

import { mapGetters, mapState } from 'vuex'

import { FileNode, Layer3DNode } from '@/models'

import { debounce } from 'lodash'

export default {
  name: 'Map',

  components: {
    MglMap,
    FeatureViewer,
    LayerMgrCtrl,
    GlbMeshMgr,
    SearchBar,
    AnimationPlayer,
    DrawerView,
    SimulationModelSelect
  },

  provide() {
    return {
      mapNavHeight: 48 // 改這個也得改 @/styles/map/variables.scss 的 map-nav-height
    }
  },

  data: () => ({
    isInitializing: false,
    isLoadingMap: true,
    showFeatureViewer: false,
    mapboxAccessToken: settings.MAPBOX_TOKEN,
    itemsUpdated: false,
    folders: [],
    hoveredFeatureIds: [] // 用陣列紀錄movemove事件傳遞造成多個hover(mouseleave卻不會事件傳遞)
  }),

  computed: {
    ...mapState({
      layerTree: state => state.map.layerTree,
      // zoom: state => state.map.zoom,
      center: state => state.map.center
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      isMapModelByRoute: 'map/isMapModelByRoute',
      isLiteModelByRoute: 'map/isLiteModelByRoute',
      isDrawerModelByRoute: 'map/isDrawerModelByRoute',
      glbLayerNodes: 'map/glbLayerNodes'
    }),

    map: {
      get() {
        return this.$store.getters['map/map']
      },
      set(newVal) {
        this.$store.dispatch('map/initLayerTree', {
          map: newVal
        })
      }
    },
    zoom: {
      get() {
        return this.$store.state.map.zoom
      },
      set(newVal) {
        this.$store.commit('map/setState', {
          zoom: newVal
        })
      }

    },
    animationFolder: {
      get() {
        return this.$store.state.map.animationFolder
      },
      set(newVal) {
        this.$store.commit('map/setState', {
          animationFolder: newVal
        })
      }
    },
    scale: {
      get() {
        return this.$store.state.map.scale
      },
      set(newVal) {
        this.$store.commit('map/setState', {
          scale: newVal
        })
      }
    },

    isLoading() {
      return this.isLoadingMap || this.isInitializing
    },
    isMapModel() {
      return this.isMapModelByRoute(this.$route)
    },
    isLiteModel() {
      return this.isLiteModelByRoute(this.$route)
    },
    isDrawerModel() {
      return this.isDrawerModelByRoute(this.$route)
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    projectS3BucketId() {
      return this.project?.s3BucketId || ''
    }
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$store.dispatch('map/init')
    this.$store.dispatch('simulation/init')
  },

  methods: {
    init() {
      this.isInitializing = true
      this.$store.dispatch('map/initMap')
      this.fetchModels()
      return this.fetchProject()
        .then(() => Promise.all([
          this.fetchDashboards(),
          this.fetchDatasourceId()
        ])
        )
        .finally(async () => {
          this.isInitializing = false
        })
    },
    fetchModels() {
      return this.$store.dispatch('simulation/fetchModels')
    },
    fetchProject() {
      return this.$store.dispatch('projects/fetchProject', this.$route.params)
    },
    fetchDashboards() {
      return this.$store.dispatch('dashboards/fetchDashboards', {
        project: this.project
      })
    },
    fetchDatasourceId() {
      return this.$store.dispatch('panels/fetchGrafanaDatasourceId', {
        grafanaId: this.project.grafanaId,
        projectId: (!this.project.isOwner && this.project.uuid) || null
      })
    },
    onMapLoad() {
      this.isLoadingMap = false

      this.setScale()
      this.map
        .on('click', this.onFeatureClick)
        .on('move', debounce(this.setScale, 300))
    },
    async onFileContentLoaded({ fileNode, hide = false } = {}) {
      if (!(fileNode instanceof FileNode) || !fileNode.fileContent) {
        return Promise.resolve()
      }

      return this.addLayer(fileNode, hide)
    },
    addLayer(fileNode, hide = false) {
      return this.$store
        .dispatch('map/addLayer', { fileNode })
        .then(layerNode => {
          if (layerNode instanceof Layer3DNode) {
            return
          }

          if (hide) {
            this.layerTree.setMaplayerVisibility(layerNode, 'none')
          }

          this.registerLayerEvents(layerNode)
        })
        .catch(node => {
          if (node === fileNode) {
            this.$store.dispatch('snackbar/showWarning', {
              content: this.$t('page.map.error_duplicate_load_maplayer')
            })
          }
        })
    },
    registerLayerEvents(layerNode) {
      if (
        !layerNode ||
         !this.map.getLayer(layerNode.uuid) ||
         layerNode.is3DFile
      ) {
        return
      }

      this.map.on('mousemove', layerNode.uuid, e => {
        // NOTE:用mousemove才能解決相連feature移動時變換feature hover的效果
        // 不能用mouseenter, 其只會在進入layer時觸發, 相連feature(多邊形)移動時不會觸發

        // FIXME: removeNode 應該要移除註冊的事件, 但handler傳不過去removeNode...
        const sourceUuid = layerNode?.source?.uuid
        if (!sourceUuid) {
          return
        }

        // 處理相連feature移動時, 取消前一個feature的hover state
        if (this.hoveredFeatureIds.length) {
          this.hoveredFeatureIds.forEach(featureId => {
            if (featureId.source !== layerNode.uuid) {
              return
            }
            this.map.setFeatureState(
              featureId,
              { hover: false }
            )
          })
          this.hoveredFeatureIds = this.hoveredFeatureIds
            .filter(featureId => featureId.source !== layerNode.uuid)
        }

        if (e.features.length) {
          this.map.getCanvas().style.cursor = 'pointer'

          this.hoveredFeatureIds.push({
            source: sourceUuid,
            id: e.features[0].id
          })
          this.map.setFeatureState(
            { source: sourceUuid, id: e.features[0].id },
            { hover: true }
          )
        }
      })
      this.map.on('mouseleave', layerNode.uuid, e => {
        if (this.hoveredFeatureIds.length) {
          this.hoveredFeatureIds.forEach(featureId => {
            this.map.setFeatureState(
              featureId,
              { hover: false }
            )
          })
        }
        this.hoveredFeatureIds = []
        // Reset the cursor style
        this.map.getCanvas().style.cursor = ''
      })
    },
    onFeatureClick(e) {
      if (!this.isMapModel) {
        return
      }
      // 去除非maplayer的labels
      const features = this.map.queryRenderedFeatures(e.point)
        .filter(f => f.source !== 'composite')

      const feature = features[0]

      if (feature) {
        this.showFeatureViewer = feature
      }
    },
    setScale() {
      const map = this.$refs.map.$el
      const scale = map.querySelector('.mapboxgl-ctrl-scale')

      if (!scale) {
        return
      }

      const scaleText = scale.innerText
      const unit = scaleText.slice(-2)
      const exponent = unit[0] === 'k' ? 3 : 0
      const scaleNum = parseInt(scaleText) * Math.pow(10, exponent)

      if (this.scale && scaleNum !== this.scale) {
        this.glbLayerNodes.forEach(glbLayerNode => {
          glbLayerNode.meshes.forEach(mesh => {
            if (mesh.axis && mesh.axis.isEnabled()) {
              glbLayerNode.createAxis(mesh, {
                unit: scaleNum,
                zoom: this.zoom
              })
            }
          })
        })
      }

      this.scale = scaleNum
    }
  }
}
</script>

<style lang="scss">
.map-file-manager {
  height: 84vh;
}
</style>

<style lang="scss" scoped>
</style>
