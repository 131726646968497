<template>
  <v-dialog
    v-model="dialog"
    width="60rem"
    hide-overlay
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      >
        <v-btn
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('massbalance_chart') }}
        </v-btn>
      </slot>
    </template>

    <v-card
      :elevation="0"
    >
      <v-card-title
        class="
          text-subtitle-1
          bg-ease white--text
        "
      >
        <v-row
          no-gutters
          align="center"
        >
          <v-col class="draggable">
            {{ $t('massbalance_chart') }}
          </v-col>

          <v-col cols="auto">
            <v-btn
              icon
              dark
              x-small
              @click="close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-6">
        <v-row
          class="mt-6"
          no-gutters
          justify="center"
        >
          <highcharts :options="chartOptions" />
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { fixedDigits } from '@/assets/js/utils'

const precisionChartValue = 5

export default {
  name: 'MassbalanceChartDialog',

  components: {
    highcharts: Chart
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    mbData: {
      type: Object,
      required: true
    }
  },

  data: vm => ({
    checkedAll: false,
    show: false
  }),

  computed: {
    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal

        this.$emit('input', newVal)
      }
    },

    series() {
      if (!Array.isArray(this.mbData.months)) {
        return []
      }

      return this.mbData.months
    },
    chartOptions() {
      const influx = this.mbData.output_plot_grid.influx
      const outflux = this.mbData.output_plot_grid.outflux
      const months = this.mbData.months
      return {
        accessibility: {
          enabled: false
        },
        title: {
          text: this.$t('massbalance_chart')
        },
        chart: {
          type: 'line',
          width: 730,
          height: 300
        },
        legend: {
          enabled: true
        },
        xAxis: {
          title: {
            text: this.$t('date')
          },
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: "%Y '%b"
          }
        },
        yAxis: {
          title: {
            text: 'Flux'
          }
        },
        series: [
          {
            name: 'In Flux',
            visible: true,
            data: months.map((month, iMonth) => [
              +new Date(month),
              fixedDigits(influx[iMonth], precisionChartValue)
            ])
          },
          {
            name: 'Out Flux',
            visible: true,
            data: months.map((month, iMonth) => [
              +new Date(month),
              fixedDigits(outflux[iMonth], precisionChartValue)
            ])
          }
        ]
      }
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    }
  },

  methods: {
    close() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.legend-checkbox {
  ::v-deep .v-label {
    font-size: 0.9rem;
  }
}
</style>
