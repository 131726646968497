<template>
  <v-expansion-panels
    v-if="showMapsetNodes.length"
    v-model="expansionMapsets"
    class="mapset-list-expansion-panels"
    multiple
  >
    <v-expansion-panel
      v-for="(mapsetNode, iMapsetNode) in showMapsetNodes"
      :key="mapsetNode.uuid ||iMapsetNode"
    >
      <v-expansion-panel-header
        class="primary white--text"
        hide-actions
      >
        <v-row
          no-gutters
          align="center"
          style="max-width: 100%;"
        >
          <v-col cols="auto">
            <v-btn
              :ripple="false"
              :disabled="!iMapsetNode"
              small
              icon
              dark
              @click.stop="moveToFront(mapsetNode)"
            >
              <v-icon small>
                mdi-chevron-up
              </v-icon>
            </v-btn>
            <v-btn
              :ripple="false"
              :disabled="iMapsetNode===showMapsetNodes.length-1"
              small
              icon
              dark
              @click.stop="moveToBack(mapsetNode)"
            >
              <v-icon small>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </v-col>
          <!-- <v-col cols="auto">
            {{ `${iMapsetNode + 1}`.padStart(2,'0') }}
          </v-col> -->
          <v-col
            class="mx-1"
            cols="auto"
          >
            <v-btn
              icon
              small
              @click.stop="toggleVisible(mapsetNode)"
            >
              <v-icon
                small
                color="white"
              >
                {{ mapsetNode.visible?'mdi-eye':'mdi-eye-off' }}
              </v-icon>
            </v-btn>
          </v-col>
          <!-- NOTE: 不設定寬度自動 truncate的話不能設cols="auto"
                   flex-basis不能設為auto-->
          <v-col
            class="text-truncate"
            :title="mapsetNode.name"
          >
            {{ mapsetNode.name }}
          </v-col>
          <v-col
            v-if="!isReadonly"
            class="ml-auto"
            cols="auto"
          >
            <v-btn
              color="white"
              small
              icon
              @click.stop="removeNode(mapsetNode)"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <template #actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <LayerList
          :mapset-node="mapsetNode"
          v-on="$listeners"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import LayerList from './LayerList'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MapsetList',

  components: {
    LayerList
  },

  computed: {
    ...mapState({
      currentFolderNode: state => state.map.currentFolderNode,
      currentLayerNode: state => state.map.currentLayerNode
    }),
    ...mapGetters({
      isMapModelByRoute: 'map/isMapModelByRoute'
    }),

    expansionMapsets: {
      get() {
        return this.$store.state.map.expansionMapsets
      },
      set(newVal) {
        this.$store.commit('map/setState', {
          expansionMapsets: newVal
        })
      }
    },

    isMapModel() {
      return this.isMapModelByRoute(this.$route)
    },
    isReadonly() {
      return !this.isMapModel
    },
    mapsetNodes() {
      const mapsetNodes = this.currentFolderNode?.children

      if (!Array.isArray(mapsetNodes)) {
        return []
      }

      return mapsetNodes
    },
    showMapsetNodes() {
      return [...this.mapsetNodes].reverse()
    }
  },

  watch: {
    currentFolderNode(newVal) {
      this.$nextTick()
        .then(() => {
          const folder = this.currentLayerNode?.parent?.parent
          if (folder === newVal) {
            const mapset = this.currentLayerNode?.parent
            const mapsetIndex = this.showMapsetNodes.findIndex(mapsetNode => mapsetNode.uuid === mapset.uuid)
            this.expansionMapsets = [mapsetIndex]
          }
        })
    },
    currentLayerNode(newVal) {
      this.$nextTick()
        .then(() => {
          // 展開 currentLayerNode 的 mapset
          const folder = newVal?.parent?.parent
          if (folder !== this.currentFolderNode) {
            return
          }

          const mapset = newVal?.parent
          // const mapsetIndex = this.showMapsetNodes.findIndex(mapsetNode => mapsetNode.uuid === mapset.uuid)
          const mapsetIndex = mapset?.index

          if (mapsetIndex == null || mapsetIndex === -1) {
            return
          }

          if (this.expansionMapsets.indexOf(mapsetIndex) === -1) {
            this.expansionMapsets.push(mapsetIndex)
          }
        })
    }
  },

  methods: {
    toggleVisible(node) {
      this.$store.dispatch('map/toggleVisible', { node })
    },
    removeNode(node) {
      this.$store.dispatch('map/removeNode', { node })
    },
    moveToFront(node) {
      const beforeNode = this.mapsetNodes[node.index + 2]

      this.$store.dispatch('map/moveNode', {
        node,
        beforeNode
      })
    },
    moveToBack(node) {
      const beforeNode = this.mapsetNodes[node.index - 1]

      if (!beforeNode) {
        return
      }

      this.$store.dispatch('map/moveNode', {
        node,
        beforeNode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mapset-list-expansion-panels {
  .v-expansion-panel-header {
    padding: 8px 0.875rem;
    min-height: 30px;
    font-size: 1rem;
  }
}
</style>
