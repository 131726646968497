<template>
  <v-row
    class="tab-item pa-3 pt-5"
    align="stretch"
  >
    <v-col lg="6">
      <ParameterInput
        :parameter="Well.TopElevation"
      />
    </v-col>
    <v-col lg="6">
      <ParameterInput
        :parameter="Well.BottomElevation"
      />
    </v-col>
    <v-col lg="3">
      <ParameterInput
        :parameter="Well.WellType"
        style="height: 100%;"
      />
    </v-col>
    <v-col lg="9">
      <ParameterInput
        v-for="(parameter, name) in leftParameters"
        :key="name"
        :parameter="parameter"
      />
    </v-col>
    <v-col lg="12">
      <ParameterInput
        :parameter="Well.MonitoringWell"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseParametersForm from '../../Base/BaseParametersForm'
import ParameterInput from '@/components/Simulation/ParameterInput'
import { cutObject } from '@/assets/js/utils'

export default {
  name: 'Well',

  components: {
    ParameterInput
  },
  extends: BaseParametersForm, // 聯繫父層form的相關script

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  computed: {
    Well() {
      return this.parameters?.Well || {}
    },
    leftParameters() {
      return cutObject(this.Well, 'PrescribedHead', 'Concentration')
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-item {
  > .col {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>
