var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(
        _vm.currentLayerNode &&
          !_vm.currentLayerNode.is3DFile
      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('Style2DEditor',{attrs:{"layer-node":_vm.currentLayerNode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text text-capitalize",attrs:{"fab":"","large":""}},'v-btn',attrs,false),on),[_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',[_vm._v("mdi-palette")])],1),_c('v-col',[_c('span',{staticStyle:{"font-size":"0.625rem"}},[_vm._v("Style 2D")])])],1)],1)]}}],null,false,2290106887)})],1):_vm._e(),(
        _vm.currentLayerNode &&
          !_vm.currentLayerNode.is3DFile &&
          [
            _vm.SIM_MODEL.MODFLOW,
            _vm.SIM_MODEL.FEMWATER_MESH
          ].includes(_vm.simulationModel)
      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('ModflowParametersMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
      var edit = ref.edit;
return [_c('v-badge',{attrs:{"value":edit,"bordered":"","icon":"mdi-pencil","color":"secondary","overlap":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text text-capitalize",attrs:{"fab":"","large":""}},'v-btn',attrs,false),on),[_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',[_vm._v("mdi-tune")])],1),_c('v-col',[_c('span',{staticStyle:{"font-size":"0.625rem"}},[_vm._v(_vm._s(_vm.$t('simulation_parameter_abbreviation')))])])],1)],1)],1)]}}],null,false,2449461022)})],1):_vm._e(),(
        _vm.currentLayerNode &&
          _vm.currentLayerNode.hasLegends
      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('LegendsDialog',{attrs:{"layer-node":_vm.currentLayerNode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text text-capitalize",attrs:{"fab":"","large":""}},'v-btn',attrs,false),on),[_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',[_vm._v("mdi-invert-colors")])],1),_c('v-col',[_c('span',{staticStyle:{"font-size":"0.625rem"}},[_vm._v("Legends")])])],1)],1)]}}],null,false,2140027448)})],1):_vm._e(),(_vm.isPostprocessModel)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.canSlice),expression:"canSlice"}],attrs:{"cols":"auto"}},[_c('Slice',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text text-capitalize",attrs:{"fab":"","large":"","loading":_vm.isSlicing}},'v-btn',attrs,false),on),[_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',[_vm._v("mdi-segment")])],1),_c('v-col',[_c('span',{staticStyle:{"font-size":"0.625rem"}},[_vm._v("Slice")])])],1)],1)]}}],null,false,1470478647)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[(
          (_vm.isMapModel || _vm.isPostprocessModel) &&
            _vm.canMbSlice &&
            _vm.simulationModel === _vm.SIM_MODEL.MASSBALANCE
        )?_c('MassbalanceChart',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
        var loading = ref.loading;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text text-capitalize",attrs:{"fab":"","large":"","loading":loading}},'v-btn',attrs,false),on),[_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',[_vm._v("mdi-waves")])],1),_c('v-col',[_c('span',{staticStyle:{"font-size":"0.625rem"}},[_vm._v("水平衡"),_c('br'),_vm._v("圖表")])])],1)],1)]}}],null,false,1859950700)}):_vm._e()],1),(
        (_vm.isMapModel || _vm.isPostprocessModel) &&
          _vm.canMbSlice &&
          _vm.simulationModel === _vm.SIM_MODEL.MASSBALANCE
      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('MassbalanceSlice',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
      var loading = ref.loading;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text text-capitalize",attrs:{"fab":"","large":"","loading":loading}},'v-btn',attrs,false),on),[_c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',[_vm._v("mdi-waves-arrow-right")])],1),_c('v-col',[_c('span',{staticStyle:{"font-size":"0.625rem"}},[_vm._v("水平衡"),_c('br'),_vm._v("畫線")])])],1)],1)]}}],null,false,2763810394)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }