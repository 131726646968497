<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
  >
    <template #activator="{ on, attrs }">
      <v-tooltip top>
        <template #activator="{ on:onTooltip, attrs:attrsTooltip }">
          <div
            v-bind="attrsTooltip"
            v-on="onTooltip"
          >
            <slot
              name="activator"
              :on="on"
              :attrs="attrs"
            >
              <v-btn
                v-bind="attrs"
                color="secondary"
                :disabled="!fileNode"
                small
                text
                v-on="on"
              >
                {{ $t('add_common_property') }}
              </v-btn>
            </slot>
          </div>
        </template>
        <span>{{ $t('page.map.tip_add_common_property') }}</span>
      </v-tooltip>
    </template>

    <v-form
      ref="form"
      v-model="isValid"
      @submit.prevent="submit"
    >
      <v-card class="action-dialog-card">
        <v-card-text>
          <v-row
            class="
              draggable
              grey--text
              text--darken-4
            "
            no-gutters
          >
            {{ $t('add_common_property') }}
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formData.key"
                :label="$t('key_name')"
                :rules="rulesKey"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="formData.value"
                :label="$t('key_value')"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-0">
          <v-spacer />
          <v-btn
            class="action-btn"
            color="primary"
            rounded
            :loading="isLoading"
            @click="submit"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            class="action-btn ml-3"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { validateRequired } from '@/assets/js/validate'

import {
  FEATURE_MULTI_LAYER
} from '@/models/utils'

import { mapState, mapGetters } from 'vuex'

const formatFormData = () => ({
  key: '',
  value: ''
})

const rulesKey = () => {
  return [
    validateRequired
  ]
}

export default {
  name: 'AddCommonPropertyDialog',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    feature: {
      type: Object,
      required: true
    }
  },

  data: vm => ({
    isValid: true,
    show: vm.value,
    isLoading: false,
    formData: formatFormData()
  }),

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      layerTree: state => state.map.layerTree
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      map: 'map/map'
    }),
    rulesKey,

    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    mapLayerId() {
      return this.feature?.layer?.id
    },
    fileNode() {
      return this.fileTree.findNodeBF(this.fileTree.root, this.mapLayerId)
    },
    layerNode() {
      if (!this.layerTree) {
        return
      }
      return this.layerTree.findNodeBF(this.layerTree.root, this.mapLayerId)
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.dialog = newVal
      },
      immediate: true
    },
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
      immediate: true
    }
  },

  methods: {
    init() {
      this.formData = formatFormData()
      this.resetValidation()
    },
    cancel() {
      this.dialog = false
    },
    closeAll() {
      this.dialog = false
      this.$emit('cancel')
    },
    validate() {
      return this.$refs.form?.validate()
    },
    resetValidation() {
      return this.$refs.form?.resetValidation()
    },
    async submit() {
      await this.validate()

      if (!this.isValid) {
        return
      }

      const { key: newKey, value: newVal } = this.formData

      const { features, ...data } = this.fileNode.fileContent
      const newData = {
        ...data,
        features: features.map(feature => {
          const isMultiLayer = FEATURE_MULTI_LAYER.is(feature)

          if (isMultiLayer) {
            const layers = FEATURE_MULTI_LAYER.getLayerProperties(feature)
            layers.forEach(layer => {
              const properties = layer[1]
              if (Object.prototype.hasOwnProperty.call(properties, newKey)) {
                return
              }

              Object.assign(properties, {
                [newKey]: newVal
              })
            })
            return feature
          }

          if (Object.prototype.hasOwnProperty.call(feature.properties, newKey)) {
            return feature
          }

          Object.assign(feature.properties, {
            [newKey]: newVal
          })

          return feature
        })
      }

      this.isLoading = true
      this.map.getSource(this.feature.source).setData(newData)
      this.fileNode.setFileContent(newData)
      this.layerNode.source.setData(newData)
      return this.$store.dispatch('files/updateMaplayerData', {
        project: this.project,
        fileNode: this.fileNode,
        jsonContent: newData
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_created')
          })

          this.closeAll()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.action-dialog-card {
  padding: 24px 2rem;

  .v-card__subtitle,
  .v-card__text {
    font-size: 1rem;
  }
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
