<template>
  <v-card
    min-height="200"
  >
    <v-card-text>
      <v-row
        no-gutters
        align="center"
      >
        <v-col cols="auto">
          <!-- NOTE: 必須要v-model綁visible才會正常work! -->
          <v-checkbox
            v-model="legend.visible"
            :label="$t('page.map.show_legend')"
            :disabled="!hasLegends"
          />
        </v-col>
        <v-col
          v-if="!hasLegends"
          class="ml-2 primary--text"
          cols="auto"
        >
          {{ $t('page.map.no_mesh_in_legend',{scalarsName:legend.scalarsName}) }}
        </v-col>
        <v-col
          v-if="!isReadonly"
          class="ml-auto"
          cols="auto"
        >
          <v-tooltip
            bottom
            :disabled="!isPloting"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  color="primary"
                  rounded
                  :disabled="isPloting"
                  :loading="legend.isPloting"
                  @click="submit"
                >
                  <v-icon left>
                    mdi-invert-colors
                  </v-icon>
                  {{ $t('set_up') }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t('page.map.plotting') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-form
        ref="form"
        v-model="isValid"
        :disabled="isPloting"
        :readonly="isReadonly"
      >
        <v-text-field
          v-if="isReadonly"
          :value="formData.colormapName"
          label="Color Map"
        />
        <v-select
          v-else
          v-model="formData.colormapName"
          label="Color Map"
          :readonly="isReadonly"
          :items="colormapNames"
          :rules="rulesColormapName"
        />

        <v-text-field
          v-model="formData.colormapLevel"
          label="Level"
          type="number"
          :min="1"
          :rules="rulesColormapLevel"
        />
        <v-text-field
          v-model="formData.rangeMin"
          label="Range Min"
          type="number"
          :suffix="
            rangeMinMeshes == null
              ? ''
              : $t('page.map.data_min',{number:rangeMinMeshes})
          "
        />
        <v-text-field
          v-model="formData.rangeMax"
          label="Range Max"
          type="number"
          :suffix="
            rangeMaxMeshes == null
              ? ''
              : $t('page.map.data_max',{number:rangeMaxMeshes})
          "
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { LayerNode } from '@/models'
import { setProperties } from '@/models/utils'

import { validateRequired } from '@/assets/js/validate'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'LegendCard',

  props: {
    layerNode: {
      type: LayerNode,
      required: true
    },
    legend: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isValid: true,
    formData: {},
    rulesColormapName: [
      validateRequired
    ]
  }),

  computed: {
    ...mapState({
      isPloting: state => state.postprocess.plot.isPloting,
      colormapNames: state => state.postprocess.plot.colormapNames
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      isPostprocessModelByRoute: 'map/isPostprocessModelByRoute'
    }),

    rulesColormapLevel() {
      return [
        validateRequired,
        v => (v >= 1 && v % 1 === 0) || this.$t('validate.number_positive_integer'),
        v => v <= 2000 || this.$t('validate.upper_limit', { number: 2000 })
      ]
    },

    colormapName: {
      get() {
        return this.legend.colormapName
      },
      set(newVal) {
        setProperties(this.legend, {
          colormapName: newVal
        })
      }
    },
    colormapLevel: {
      get() {
        return this.legend.colormapLevel
      },
      set(newVal) {
        setProperties(this.legend, {
          colormapLevel: newVal
        })
      }
    },

    isPostprocessModel() {
      return this.isPostprocessModelByRoute(this.$route)
    },
    canPlot() {
      return this.isPostprocessModel
    },
    isReadonly() {
      return this.readonly || !this.canPlot
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    hasLegends() {
      return Array.isArray(this.legend.legends)
    },
    meshes() {
      return this.layerNode.meshes
        .filter(
          mesh =>
            mesh?.settings?.activeScalarsName === this.legend.scalarsName &&
          mesh.isEnabled()
        )
    },
    rangeMinMeshes() {
      if (!this.meshes.length) {
        return
      }

      const scalarsName = this.legend.scalarsName
      let min = Math.min(
        ...this.meshes
          .map(mesh => mesh.settings?.legends?.[scalarsName]?.rangeMin)
      )
      if (Number.isNaN(min)) {
        return null
      }
      const abs = Math.abs(min)
      if (abs >= 1000 || abs <= 0.01) {
        min = min.toExponential(2)
      } else if (abs >= 100) {
        min = Math.round(min * 10) / 10
      } else if (abs > 1) {
        min = Math.round(min * 100) / 100
      } else {
        min = Math.round(min * 1000) / 1000
      }

      return min
    },
    rangeMaxMeshes() {
      if (!this.meshes.length) {
        return
      }

      const scalarsName = this.legend.scalarsName
      let max = Math.max(
        ...this.meshes
          .map(mesh => mesh.settings?.legends?.[scalarsName]?.rangeMax)
      )
      if (Number.isNaN(max)) {
        return null
      }
      const abs = Math.abs(max)
      if (abs >= 1000 || abs <= 0.01) {
        max = max.toExponential(2)
      } else if (abs >= 100) {
        max = Math.round(max * 10) / 10
      } else if (abs > 1) {
        max = Math.round(max * 100) / 100
      } else {
        max = Math.round(max * 1000) / 1000
      }
      return max
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    },
    legend: {
      handler(newVal) {
        if (newVal) {
          this.formatFormData()
        }
      },
      immediate: true
    }
  },

  methods: {
    formatFormData() {
      this.formData = {
        scalarsName: this.legend.scalarsName,
        colormapName: this.legend.colormapName,
        colormapLevel: this.legend.colormapLevel,
        rangeMin: this.legend.rangeMin,
        rangeMax: this.legend.rangeMax
      }
    },
    async submit() {
      await this.$refs.form.validate()

      if (!this.isValid) {
        return
      }

      setProperties(this.legend, {
        isPloting: true
      })
      return this.$store.dispatch('postprocess/plot/start', {
        project: this.project,
        glbLayerNode: this.layerNode,
        plotSettings: this.formData
      })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })

          throw error
        })
        .finally(() => {
          setProperties(this.legend, {
            isPloting: false
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
