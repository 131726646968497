<template>
  <v-snackbar
    v-if="drawer"
    class="drawer-options-snackbar"
    :timeout="-1"
    :value="snack"
    color="primary"
    top
    outlined
  >
    <v-row no-gutters>
      <v-col
        v-if="!currentDrawType||currentDrawType==='point'"
        cols="auto"
      >
        <v-btn
          icon
          x-small
          :title="$t('draw_point')"
          @click="onChangeDrawMode('draw_point')"
        >
          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="!currentDrawType||currentDrawType==='lineString'"
        class="ml-2"
        cols="auto"
      >
        <v-btn
          icon
          x-small
          :title="$t('draw_line_string')"
          @click="onChangeDrawMode('draw_line_string')"
        >
          <v-icon>mdi-vector-polyline</v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="!currentDrawType||currentDrawType==='polygon'"
        class="ml-2"
        cols="auto"
      >
        <v-btn
          icon
          x-small
          :title="$t('draw_polygon')"
          @click="onChangeDrawMode('draw_polygon')"
        >
          <v-icon>mdi-vector-polygon-variant</v-icon>
        </v-btn>
      </v-col>

      <v-spacer />

      <template v-if="currentDrawType">
        <v-col
          cols="auto"
        >
          <v-btn
            :disabled="isDrawing"
            icon
            x-small
            :title="$t('group')"
            @click="combineFeatures"
          >
            <v-icon>mdi-vector-combine</v-icon>
          </v-btn>
        </v-col>
        <v-col
          class="ml-4"
          cols="auto"
        >
          <v-btn
            :disabled="isDrawing"
            icon
            x-small
            :title="$t('ungroup')"
            @click="uncombineFeatures"
          >
            <v-icon>mdi-vector-difference-ab</v-icon>
          </v-btn>
        </v-col>

        <v-spacer />
      </template>

      <v-col
        cols="auto"
      >
        <v-btn
          icon
          x-small
          @click="drawer.trash"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DrawerOptions',

  data: () => ({
    snack: true
  }),

  computed: {
    ...mapState({
      drawer: state => state.map.drawer.drawer,
      currentDrawType: state => state.map.drawer.currentDrawType
    }),

    isDrawing: {
      get() {
        return this.$store.state.map.drawer.isDrawing
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { isDrawing: newVal })
      }
    }
  },

  methods: {
    onChangeDrawMode(mode) {
      this.isDrawing = true

      this.drawer.changeMode(mode)
    },
    combineFeatures() {
      this.drawer.combineFeatures()
    },
    uncombineFeatures() {
      this.drawer.uncombineFeatures()
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-options-snackbar.v-snack {
  z-index: 100;

  ::v-deep {
    .v-snack__wrapper {
      min-height: unset;

      .v-snack__content {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
}
</style>
