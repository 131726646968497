const regNonNumber = /[^0-9]/g

export const removeTrailingSlash = str => {
  if (typeof str !== 'string') return str

  return str.replace(/\/+$/, '')
}

export const cutObject = (obj, from = 0, last) => {
  // from, last支援以obj的key為搜尋子
  const objKeys = Object.keys(obj)

  if (!Array.isArray(objKeys)) return obj

  // 若from,last是字串代表用obj key做定位
  let begin = from
  if (`${begin}`.match(regNonNumber)) {
    begin = objKeys.indexOf(`${begin}`)
    if (begin === -1) return obj
  } else if (typeof begin === 'string') {
    begin = parseInt(begin)
    if (Number.isNaN(begin)) return obj
  }

  let end = last == null ? objKeys.length - 1 : last
  if (`${end}`.match(regNonNumber)) {
    end = objKeys.indexOf(`${end}`)
    if (end === -1) {
      end = objKeys.length - 1
    }
  } else if (typeof end === 'string') {
    end = parseInt(end)
    if (Number.isNaN(end)) {
      end = objKeys.length - 1
    }
  }
  end += 1

  return Object.fromEntries(
    objKeys.slice(begin, end).map(key => [key, obj[key]])
  )
}

export const fixedDigits = (number, precision = 3) => {
  const num = parseFloat(number)

  if (isNaN(num)) return number

  const digits = Math.floor(Math.log10(Math.abs(number)))

  const pos = precision - 1
  const fixed = digits < pos ? pos - digits : 0

  const newVal = parseFloat(num.toFixed(fixed))

  return newVal
}
