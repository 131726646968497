<template>
  <div>
    <DrawerEditor
      v-if="drawer"
    />
    <DrawerOptions
      v-if="drawer"
    />
  </div>
</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import DrawerEditor from './Dialogs/DrawerEditor.vue'
import DrawerOptions from './Dialogs/DrawerOptions.vue'
import { LayerNode } from '@/models'

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { camelCase } from 'lodash'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DrawerView',

  components: {
    DrawerEditor,
    DrawerOptions
  },

  computed: {
    ...mapState({
      layerTree: state => state.map.layerTree
    }),
    ...mapGetters({
      map: 'map/map',
      isEdit: 'map/drawer/isEdit'
    }),

    drawer: {
      get() {
        return this.$store.state.map.drawer.drawer
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { drawer: newVal })
      }
    },
    isDrawing: {
      get() {
        return this.$store.state.map.drawer.isDrawing
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { isDrawing: newVal })
      }
    },
    currentDrawType: {
      get() {
        return this.$store.state.map.drawer.currentDrawType
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { currentDrawType: newVal })
      }
    },
    hasUpdate: {
      get() {
        return this.$store.state.map.drawer.hasUpdate
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { hasUpdate: newVal })
      }
    },

    layerNodes() {
      if (!this.layerTree || !this.layerTree.root) {
        return []
      }

      return this.layerTree.getLeavesDF(this.layerTree.root, LayerNode, node => node.fileContent)
    }
  },

  mounted() {
    this.initDrawer()
    // this.hideLayerNodes()
  },

  beforeDestroy() {
    this.removeDrawer()
    // this.recoverLayerNodesVisibility()
  },

  methods: {
    initDrawer() {
      this.drawer = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          point: false,
          line_string: false,
          polygon: false,
          trash: false,
          combine_features: false,
          uncombine_features: false
        }
      })

      this.map.addControl(this.drawer, 'bottom-right')
      this.map.on('draw.create', this.onDrawCreate)
      this.map.on('draw.delete', this.onDrawDelete)
      this.map.on('draw.update', this.onDrawUpdate)
      this.map.on('draw.modechange', this.onDrawModeChange)
    },
    removeDrawer() {
      this.map.removeControl(this.drawer)
      this.map.off('draw.create', this.onDrawCreate)
      this.map.off('draw.delete', this.onDrawDelete)
      this.map.off('draw.update', this.onDrawUpdate)
      this.map.off('draw.modechange', this.onDrawModeChange)

      this.$store.dispatch('map/drawer/init')
    },
    onDrawCreate(e) {
      this.hasUpdate = true

      if (!this.currentDrawType) {
        this.currentDrawType = camelCase(e.features[0].geometry.type)
      }
    },
    onDrawDelete(e) {
      this.hasUpdate = true

      const remainingFeatures = this.drawer.getAll().features
      if (!remainingFeatures.length) {
        this.currentDrawType = null

        if (!this.isEdit) {
          this.hasUpdate = false
        }
      }
    },
    onDrawUpdate(e) {
      this.hasUpdate = true
    },
    onDrawModeChange(e) {
      // 自己觸發的mode change不會發出這event
      if (e.mode === 'simple_select') {
        this.isDrawing = false
      }
    }
    // hideLayerNodes() {
    //   this.layerNodes.forEach(layerNode => {
    //     this.layerTree.setMaplayerVisibility(layerNode, 'none')
    //   })
    // },
    // recoverLayerNodesVisibility() {
    //   this.layerNodes.forEach(layerNode => {
    //     this.layerTree.setMaplayerVisibility(layerNode)
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped></style>
