<template>
  <v-row class="tab-item pa-3">
    <v-col>
      <ParameterInput
        v-for="(parameter, name) in leftParameters"
        :key="name"
        :parameter="parameter"
        class="row--dense"
      />
    </v-col>

    <v-col>
      <ParameterInput
        v-for="(parameter, name) in rightParameters"
        :key="name"
        :parameter="parameter"
        class="row--dense"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseParametersForm from '../../Base/BaseParametersForm'
import ParameterInput from '@/components/Simulation/ParameterInput'
import { cutObject } from '@/assets/js/utils'

export default {
  name: 'SourcesAndSinks',

  components: {
    ParameterInput
  },
  extends: BaseParametersForm, // 聯繫父層form的相關script

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  computed: {
    SourcesAndSinks() {
      return this.parameters?.SourcesAndSinks || {}
    },
    leftParameters() {
      return cutObject(this.SourcesAndSinks, 'Rech', 'River')
    },
    rightParameters() {
      return cutObject(this.SourcesAndSinks, 'Drain', 'Evapotranspiration')
    }
  }
}
</script>

<style lang="scss" scoped></style>
