var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parameter)?_c('v-row',{class:Object.assign({}, {'row--disabled': _vm.isDisabled},
    _vm.parameter.class),attrs:{"no-gutters":"","align":"center","tag":_vm.parameter.tag || _vm.tag || 'div'}},[(_vm.hasLegend)?_c('ParameterInput',{attrs:{"parameter":_vm.parameter.legend,"tag":"legend"}}):_vm._e(),(_vm.hasChecked)?_c('v-checkbox',{attrs:{"hide-details":"","dense":"","disabled":_vm.isDisabledCheckbox},model:{value:(_vm.parameter.apply),callback:function ($$v) {_vm.$set(_vm.parameter, "apply", $$v)},expression:"parameter.apply"}}):_vm._e(),(_vm.showTextCol)?_c('v-col',{staticClass:"text-col",class:{
      'text-col--disabled': _vm.isDisabled,
      'text-col--with-input': !_vm.hasNoInput
    },attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.parameter.text)+" ")]):[_vm._v(" "+_vm._s(_vm.parameter.text || '')+" ")],(_vm.isRadio)?_c('v-radio-group',{class:Object.assign({}, {'mt-n1': true,
      'pt-0': true,
      shrink: true},
      _vm.parameter.cases.class),attrs:{"dense":"","hide-details":"auto","disabled":_vm.isDisabled},model:{value:(_vm.parameter.case),callback:function ($$v) {_vm.$set(_vm.parameter, "case", $$v)},expression:"parameter.case"}},_vm._l((_vm.radioOptions),function(caseParameter,objKey){return _c('v-row',{key:objKey,staticClass:"flex-nowrap shrink",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{class:{
          'align-self-start': caseParameter.children
        },attrs:{"cols":"auto"}},[_c('v-radio',{attrs:{"value":objKey,"disabled":caseParameter.disabled || caseParameter.rootDisabled}})],1),_c('v-col',{staticClass:"ml-0"},[_c('ParameterInput',{attrs:{"parameter":caseParameter}})],1)],1)}),1):(_vm.hasValue)?_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto","disabled":_vm.isDisabled},on:{"blur":_vm.onBlur},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),(_vm.hasUnit)?_c('v-col',{staticClass:"unit-col",class:{ 'unit-col--disabled': _vm.isDisabled },attrs:{"cols":"auto"},domProps:{"textContent":_vm._s(_vm.parameter.unit)}}):_vm._e(),(_vm.hasTargetOptions)?_c('v-col',[_c('v-select',{attrs:{"items":_vm.parameter.targetOptions,"hide-details":"auto","dense":"","outlined":"","flast":"","disabled":_vm.isDisabled},model:{value:(_vm.parameter.target),callback:function ($$v) {_vm.$set(_vm.parameter, "target", $$v)},expression:"parameter.target"}})],1):_vm._e(),_vm._t("activator",function(){return [(_vm.isButton)?_c('v-btn',_vm._b({attrs:{"disabled":_vm.isDisabled}},'v-btn',_vm.attrsActivator,false),[_vm._v(" "+_vm._s(_vm.parameter.buttonText)+" ")]):_vm._e()]},{"attrs":_vm.attrsActivator,"disabled":_vm.isDisabled,"parameter":_vm.parameter}),(_vm.hasAdditions)?_c('v-row',{staticClass:"addition-row col-auto align-self-start",class:{
      grow: !_vm.isRadio,
      shrink: _vm.isRadio
    },attrs:{"no-gutters":""}},_vm._l((_vm.parameter.additions),function(addition,objKey){return _c('ParameterInput',{key:objKey,staticClass:"shrink",attrs:{"parameter":addition}})}),1):_vm._e(),(_vm.hasChildren)?_vm._l((_vm.parameter.children),function(childParameter,objKey){return _c('ParameterInput',{key:objKey,class:{
        'row--child': _vm.parameter.tag !== 'fieldset' || objKey === 'random'
      },attrs:{"parameter":childParameter}})}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }