import { render, staticRenderFns } from "./Head.vue?vue&type=template&id=5f9dc80b&scoped=true&"
import script from "./Head.vue?vue&type=script&lang=js&"
export * from "./Head.vue?vue&type=script&lang=js&"
import style0 from "./Head.vue?vue&type=style&index=0&id=5f9dc80b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f9dc80b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
