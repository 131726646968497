var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-3","elevation":0,"height":"100%"}},[_c('v-row',{staticClass:"draggable px-8 py-3 bg-ease flex-grow-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"step-title white--text"})],1),(_vm.isMultiLayer)?_c('FeatureMultiLayerInfo',_vm._g({ref:"form",style:({
      height: _vm.roleFileNode.update
        ?'calc(100% - 75px)'
        :'calc(100% - 24px)'
    }),attrs:{"loading":_vm.isLoading,"feature":_vm.feature}},_vm.$listeners)):_c('FeatureInfoForm',_vm._g({ref:"form",style:({
      height: _vm.roleFileNode.update
        ?'calc(100% - 75px)'
        :'calc(100% - 24px)'
    }),attrs:{"loading":_vm.isLoading,"feature":_vm.feature,"properties":(_vm.feature && _vm.feature.properties) || null}},_vm.$listeners)),(_vm.roleFileNode.update)?_c('v-card-actions',{staticClass:"pa-3 px-5"},[_c('v-btn',{staticClass:"action-btn",attrs:{"rounded":"","color":"primary","loading":_vm.isLoading,"disabled":!_vm.roleFileNode.update},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('update'))+" ")]),_c('v-btn',{staticClass:"action-btn ml-5",attrs:{"rounded":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer'),(_vm.feature)?_c('AddCommonPropertyDialog',{attrs:{"feature":_vm.feature},on:{"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-btn",attrs:{"rounded":"","color":"secondary","disabled":_vm.isLoading}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('add_common_property'))+" ")])]}}],null,false,3395621613)}):_vm._e(),_c('v-spacer')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }