<template>
  <v-tabs
    v-model="tab"
    class="modflow-tabs"
    :class="{ 'v-tabs--hide-headers': !tabHeaders.length }"
    dark
    background-color="#4a4e58"
    color="white"
    hide-slider
    fixed-tabs
    mandatory
  >
    <template v-for="(tabHeader, iTabHeader) in tabs">
      <v-tab
        v-show="tabHeader.show !== false && tabHeader.showTabHeader !== false"
        :key="iTabHeader"
        :href="`#${tabHeader.component}`"
        :disabled="tabHeader.disabled"
      >
        {{ tabHeader.text }}
      </v-tab>
    </template>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(tabItem, iTabItem) in tabItems"
        :key="iTabItem"
        :value="tabItem.component"
        :disabled="tabItem.disabled"
        :transition="false"
        :reverse-transition="false"
      >
        <component
          :is="tabItem.component"
          ref="tabForms"
          :parameters="parameters"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
// extends
import BaseParametersTabs from '../Base/BaseParametersTabs'
import '../Base/BaseParametersTabs.scss'

// tab items (tab content)
import Physical from './TabItems/Physical'
import SourcesAndSinks from './TabItems/SourcesAndSinks'
import AquiferElevationsCalibrationData from './TabItems/AquiferElevationsCalibrationData'

export default {
  name: 'PolygonTabs',

  components: {
    Physical,
    SourcesAndSinks: SourcesAndSinks,
    AquiferElevationsCalibrationData
  },
  extends: BaseParametersTabs, // 聯繫父層與子層form的相關script

  props: {
    parameters: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    tab: 'Physical'
  }),

  computed: {
    tabs() {
      if (!this.parameters) return []

      return Object.values(this.parameters).filter(
        parameter => parameter.show !== false
      )
    },
    tabHeaders() {
      return this.tabs.filter(tab => tab.showTabHeader !== false)
    },
    tabItems() {
      return this.tabs.filter(tab => tab.component)
    }
  }
}
</script>

<style lang="scss" scoped></style>
