<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :persistent="isLoading"
    :retain-focus="false"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      >
        <v-btn
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('save_as_new_file') }}
        </v-btn>
      </slot>
    </template>

    <v-form
      ref="form"
      v-model="isValid"
      :disabled="isLoading"
      @submit.prevent
    >
      <v-card min-height="200">
        <v-card-title
          class="
          draggable
          bg-ease
          white--text
          px-5
          py-1
        "
          style="font-size: 1rem;"
        >
          <span>
            {{ $t('simulation_parameter_abbreviation') }}
          </span>
          <v-btn
            class="ml-auto"
            icon
            dark
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-input
            :class="{'mb-6': !formData.pathFileNode}"
            :value="formData.pathFileNode"
            :rules="rulesPathFileNode"
            hide-details="auto"
          >
            <v-subheader class="px-0">
              <span style="margin-right: 1em;">
                {{ $t('archive_mapset') }}
              </span>
              <FileSelect
                v-model="formData.pathFileNode"
                :select-file-type="FILE_TYPE.MAPSET"
                :permissions="['create']"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    color="primary"
                    dark
                    small
                    :disabled="isLoading"
                    v-on="on"
                  >
                    {{ $t('select') }}
                  </v-btn>
                </template>
              </FileSelect>
            </v-subheader>
          </v-input>
          <v-text-field
            v-if="formData.pathFileNode"
            class="flex-nowrap mb-6"
            color="primary"
            readonly
            filled
            single-line
            hide-details="auto"
          >
            <template #label>
              <template v-for="(breadcrumb, iBreadcrumb) in pathFileNodeBreadcrumbs">
                <span
                  :key="iBreadcrumb"
                  class="text-no-wrap primary--text"
                  style="font-size: 0.9rem;"
                >{{ breadcrumb }}</span>
                <v-icon
                  v-if="iBreadcrumb < pathFileNodeBreadcrumbs.length - 1"
                  :key="`${iBreadcrumb}-divder`"
                  color="primary"
                  small
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </template>
            <template #append>
              <v-btn
                color="error"
                icon
                small
                @click="formData.pathFileNode = null"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-text-field>

          <v-text-field
            v-model="formData.fileName"
            :label="$t('file_name')"
            filled
            :suffix="fileExtensionName"
            :rules="rulesFileName"
          />
        </v-card-text>

        <v-card-actions class="pa-3">
          <v-spacer />

          <v-btn
            class="action-btn"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            class="action-btn ml-3"
            rounded
            color="primary"
            type="submit"
            :loading="isLoading"
            @click="submit"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect.vue'

import { FILE_TYPE } from '@/models/utils'
import {
  validateRequired,
  validateDuplicate
} from '@/assets/js/validate'

import { mapGetters, mapState } from 'vuex'

const fileExtensionName = '.json'

const rulesFileName = vm => [
  validateRequired,
  v => validateDuplicate(vm.fileNames)(`${v}${fileExtensionName}`.toLowerCase())
]

const initFormData = () => ({
  pathFileNode: null,
  fileName: null
})

export default {
  name: 'SaveAsDialog',

  components: {
    FileSelect
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    fileContent: {
      type: [Object, String],
      default: null
    },
    parentMaplayerUuid: {
      type: String,
      default: null
    }
  },

  data: () => ({
    FILE_TYPE,
    fileExtensionName,
    show: false,
    isValid: false,
    isLoading: false,
    formData: initFormData(),
    rulesPathFileNode: [validateRequired]
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),
    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },
    rulesFileName,

    project() {
      return this.getProjectByRoute(this.$route)
    },
    fileNames() {
      const mapset = this.formData.pathFileNode

      if (!mapset) return []

      return mapset.children
        .map(file => file.name?.toLowerCase?.())
        .filter(Boolean)
    },
    pathFileNodeBreadcrumbs() {
      if (!this.formData.pathFileNode) {
        return []
      }

      return this.formData.pathFileNode.breadcrumbs
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    },
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    resetValidation() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
    },
    async submit() {
      await this.$refs.form.validate()

      if (!this.isValid) {
        return
      }

      this.isLoading = true
      const newFileNode = await this.$store.dispatch('files/createMaplayer', {
        project: this.project,
        parent: this.formData.pathFileNode,
        maplayerName: `${this.formData.fileName}${fileExtensionName}`,
        maplayerType: 'SETTINGS_FILE',
        simulationModelUuid: this.model,
        parentMaplayerUuid: this.parentMaplayerUuid
      })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })
          this.isLoading = false

          return Promise.reject(error)
        })

      if (!newFileNode || newFileNode instanceof Error) {
        return
      }

      return this.$store.dispatch('files/updateMaplayerData', {
        project: this.project,
        fileNode: newFileNode,
        jsonContent: this.fileContent
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_save')
          })
          this.close()

          this.$emit('update:editFileNode', newFileNode)
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', { content: error })
        })
        .finally(() => {
          this.isLoading = false
        })
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
