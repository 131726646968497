<script>
export default {
  name: 'BaseParametersForm',

  methods: {
    validate() {
      if (!this.$refs.form) return true

      return this.$refs.form?.validate?.()
    },
    resetValidation() {
      if (!this.$refs.form) return

      return this.$refs.form?.resetValidation?.()
    }
  }
}
</script>
